import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  gameTypeState: "",
  gameCategoryState: "",
  gameNumQuestionsState: "",
  gameDifficultyState: "",
  gameTimeOption: "",
  gameTimeState: {
    hrState: "",
    minState: "",
    secState: "",
  },
};

const gameSetupConfigSlice = createSlice({
  name: "gameSetupConfig",
  initialState,
  reducers: {
    setGameSetupConfig(state, action) {
      //   state.gameTypeState = action.payload;
      //   state.gameCategoryState = action.payload;
      //   state.gameNumQuestionsState = action.payload;
      //   state.gameDifficultyState = action.payload;
      //   state.gameTimeState = {
      //     hrState: 0,
      //     minState: 0,
      //     secState: 0,
      //   };

      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { setGameSetupConfig } = gameSetupConfigSlice.actions;
export default gameSetupConfigSlice.reducer;
