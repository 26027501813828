import React from "react";
import { useA2HS } from "./useA2HS";

import tw from "twin.macro";

const Container = tw.div`bg-white absolute inset-x-0 bottom-[12px] w-[80vw] md:w-[70vw] lg:w-[60vw] mx-auto p-2 border-2 border-gray-200 rounded-md shadow-md sm:flex justify-center text-center`;
const Message = tw.div`font-bold m-auto`;
const ButtonContainer = tw.div`flex flex-row justify-center`;
const InstallButton = tw.button`mx-2 bg-primary-500 text-white font-bold p-2 rounded-md`;

const A2HSComponent = ({ handleClose }) => {
  const [promptEvent, promptToInstall] = useA2HS();

  return (
    <>
      {promptEvent ? (
        <Container>
          <Message>Install Kuvuga to Home Screen</Message>
          <ButtonContainer>
            <InstallButton onClick={promptToInstall}>Install</InstallButton>
            <InstallButton onClick={handleClose}>Later</InstallButton>
          </ButtonContainer>
        </Container>
      ) : // <button onClick={promptToInstall}>{"please install PWA"}</button>
      null}
    </>
  );
};

export default A2HSComponent;
