import React, { useEffect } from "react";
import GlobalStyles from "styles/GlobalStyles";
import { css } from "styled-components/macro"; //eslint-disable-line

/*
 * This is the entry point component of this project. You can change the below exported default App component to any of
 * the prebuilt landing page components by uncommenting their import and export lines respectively.
 * See one of the landing page components to better understand how to import and render different components (Always
 * make sure if you are building your own page, the root component should be the AnimationRevealPage component. You can
 * disable the animation by using the disabled prop.
 *
 * The App component below is using React router to render the landing page that you see on the live demo website
 * and the component previews.
 *
 */

/* Use AnimationRevealPage as a wrapper component for your pages if you are building a custom one yourself */
// import AnimationRevealPage from "helpers/AnimationRevealPage.js";

/*
 * Hero section is the top most section on the page. It contains the header as well.
 * So you dont need to import headers
 * separately
 */

// import Hero from "components/hero/TwoColumnWithVideo.js";
// import Hero from "components/hero/TwoColumnWithInput.js";
// import Hero from "components/hero/TwoColumnWithFeaturesAndTestimonial.js";
// import Hero from "components/hero/TwoColumnWithPrimaryBackground.js";
// import Hero from "components/hero/FullWidthWithImage.js";
// import Hero from "components/hero/BackgroundAsImage.js";
// import Hero from "components/hero/BackgroundAsImageWithCenteredContent.js";

// import Features from "components/features/ThreeColSimple.js";
// import Features from "components/features/ThreeColWithSideImage.js";
// import Features from "components/features/ThreeColWithSideImageWithPrimaryBackground.js";
// import Features from "components/features/VerticalWithAlternateImageAndText.js";
// import Features from "components/features/DashedBorderSixFeatures";
// import MainFeature from "components/features/TwoColWithButton.js";
// import MainFeature from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats2.js";
// import MainFeature from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
// import FeatureWithSteps from "components/features/TwoColWithSteps.js";
// import FeatureStats from "components/features/ThreeColCenteredStatsPrimaryBackground.js";

// import Pricing from "components/pricing/ThreePlans.js";
// import Pricing from "components/pricing/ThreePlansWithHalfPrimaryBackground.js";
// import Pricing from "components/pricing/TwoPlansWithDurationSwitcher.js";

// import SliderCard from "components/cards/ThreeColSlider.js";
// import TrendingCard from "components/cards/TwoTrendingPreviewCardsWithImage.js";
// import Portfolio from "components/cards/PortfolioTwoCardsWithImage.js";
// import TabGrid from "components/cards/TabCardGrid.js";

// import Blog from "components/blogs/ThreeColSimpleWithImage.js";
// import Blog from "components/blogs/ThreeColSimpleWithImageAndDashedBorder.js";
// import Blog from "components/blogs/PopularAndRecentBlogPosts.js";
// import Blog from "components/blogs/GridWithFeaturedPost.js";

// import Testimonial from "components/testimonials/TwoColumnWithImage.js";
// import Testimonial from "components/testimonials/TwoColumnWithImageAndProfilePictureReview.js";
// import Testimonial from "components/testimonials/TwoColumnWithImageAndRating.js";
// import Testimonial from "components/testimonials/ThreeColumnWithProfileImage.js";
// import Testimonial from "components/testimonials/SimplePrimaryBackground.js";

// import FAQ from "components/faqs/SimpleWithSideImage.js";
// import FAQ from "components/faqs/SingleCol.js";
// import FAQ from "components/faqs/TwoColumnPrimaryBackground.js";

// import ContactUsForm from "components/forms/SimpleContactUs.js";
// import ContactUsForm from "components/forms/TwoColContactUsWithIllustration.js";
// import SubscribeNewsLetterForm from "components/forms/SimpleSubscribeNewsletter.js";
//
// import GetStarted from "components/cta/GetStarted.js";
// import GetStarted from "components/cta/GetStartedLight.js";
// import DownloadApp from "components/cta/DownloadApp.js";

// import Footer from "components/footers/SimpleFiveColumn.js";
// import Footer from "components/footers/FiveColumnWithInputForm.js";
// import Footer from "components/footers/FiveColumnWithBackground.js";
// import Footer from "components/footers/FiveColumnDark.js";
// import Footer from "components/footers/MiniCenteredFooter.js";

/* Ready Made Pages (from demos folder) */
// import EventLandingPage from "demos/EventLandingPage.js";
// import HotelTravelLandingPage from "demos/HotelTravelLandingPage.js";
// import AgencyLandingPage from "demos/AgencyLandingPage.js";
// import SaaSProductLandingPage from "demos/SaaSProductLandingPage.js";
// import RestaurantLandingPage from "demos/RestaurantLandingPage.js";
// import ServiceLandingPage from "demos/ServiceLandingPage.js";
// import HostingCloudLandingPage from "demos/HostingCloudLandingPage.js";

/* Inner Pages */
import KuvugaLoginPage from "pages/auth/KuvugaLoginPage.js";
import KuvugaPasscodePage from "pages/auth/KuvugaPasscodePage.js";
import KuvugaRegisterPage from "pages/auth/KuvugaRegisterPage";
// import SignupPage from "pages/Signup.js";
// import PricingPage from "pages/Pricing.js";
// import AboutUsPage from "pages/AboutUs.js";
// import ContactUsPage from "pages/ContactUs.js";
// import BlogIndexPage from "pages/BlogIndex.js";
// import TermsOfServicePage from "pages/TermsOfService.js";
// import PrivacyPolicyPage from "pages/PrivacyPolicy.js";

import ComponentRenderer from "ComponentRenderer.js";
import KuvugaLandingPage from "pages/KuvugaLandingPage.js";
import KuvugaHomeMenuPage from "pages/KuvugaHomeMenuPage.js";
import KuvugaLearningPage from "pages/KuvugaLearningPage";
import KuvugaLessonLearningPage from "pages/KuvugaLessonLearningPage";
import KuvugaLessonTablePage from "pages/KuvugaLessonTablePage";
import KuvugaLessonsPage from "pages/KuvugaLessonsPage";
import KuvugaLessonSectionPage from "pages/KuvugaLessonSectionPage";
// import KuvugaBlogIndexPage from "pages/KuvugaBlogIndex.js";
import PricingPage from "pages/KuvugaPricingPage.js";
import AboutUsPage from "pages/KuvugaAboutUs.js";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import KuvugaContactUs from "pages/KuvugaContactUs";
import KuvugaCategoriesPage from "pages/KuvugaCategoriesPage";
import KuvugaGameSetupPage from "pages/games-practice/KuvugaGameSetupPage";
// import KuvugaGameCategoriesPage from "pages/KuvugaGameCategoriesPage";
import KuvugaTrueFalseGamePage from "pages/games-practice/KuvugaTrueFalseGamePage";
import KuvugaMCQGamePage from "pages/games-practice/KuvugaMCQGamePage";
import KuvugaFillBlanksGamePage from "pages/games-practice/KuvugaFillBlanksGamePage";
import KuvugaLanguageListPage from "pages/KuvugaLanguageListPage";
import KuvugaReferralSourcePage from "pages/KuvugaReferralSourcePage";

// analytics
import CustomAnalyticsPage from "pages/analytics/CustomAnalyticsPage";

import pingServerRender from "utils/pingRender";
import { getCookie } from "utils/cookiesHandler";
import { isInStorage } from "utils/isInStorage";
import initializeDatabase from "./dexie/initDB";

// custom analytics
import { kuvugaVisits } from "utils/customAnalyticsUtils";

export default function App() {
  // If you want to disable the animation just use the disabled `prop` like below on your page's component
  // return <AnimationRevealPage disabled>xxxxxxxxxx</AnimationRevealPage>;

  // ping server
  pingServerRender();

  // custom analytics
  useEffect(() => {
    kuvugaVisits();
  }, []);

  // check indexedDB
  const UserDatabase = "UserDatabase";
  initializeDatabase(UserDatabase);

  return (
    <>
      <GlobalStyles />
      <Router>
        <Routes>
          <Route
            path="/components/:type/:subtype/:name"
            element={<ComponentRenderer />}
          />
          <Route
            path="/components/:type/:name"
            element={<ComponentRenderer />}
          />
          {/* <Route path="/" element={<KuvugaLandingPage />} /> */}
          {/* <Route path="/" element={<KuvugaLoginPage />} /> */}
          {isInStorage(localStorage.getItem("learning-language")) === true ||
          isInStorage(getCookie("kvglan")) === true ? (
            <Route path="/" element={<KuvugaHomeMenuPage />} />
          ) : (
            <Route path="/" element={<KuvugaLanguageListPage />} />
          )}
          <Route path="/" element={<KuvugaHomeMenuPage />} />
          <Route path="/home-menu" element={<KuvugaHomeMenuPage />} />
          <Route
            path="/referral-source"
            element={<KuvugaReferralSourcePage />}
          />
          <Route path="/landing" element={<KuvugaLandingPage />} />
          <Route path="/language-list" element={<KuvugaLanguageListPage />} />
          <Route path="/learn-categories" element={<KuvugaCategoriesPage />} />
          <Route path="/review-categories" element={<KuvugaCategoriesPage />} />
          <Route path="/game-categories" element={<KuvugaGameSetupPage />} />
          {/* <Route
            path="/game-categories"
            element={<KuvugaGameCategoriesPage />}
          /> */}
          <Route path="/true-false" element={<KuvugaTrueFalseGamePage />} />
          <Route path="/multiple-choice" element={<KuvugaMCQGamePage />} />
          <Route path="/fill-blanks" element={<KuvugaFillBlanksGamePage />} />
          <Route path="/learning" element={<KuvugaLearningPage />} />
          <Route path="/reviewing" element={<KuvugaLearningPage />} />
          <Route
            path="/lesson-learning"
            element={<KuvugaLessonLearningPage />}
          />
          <Route
            path="/lesson-reviewing"
            element={<KuvugaLessonLearningPage />}
          />
          <Route path="/lesson-table" element={<KuvugaLessonTablePage />} />
          <Route path="/lessons" element={<KuvugaLessonsPage />} />
          <Route path="/lesson-section" element={<KuvugaLessonSectionPage />} />
          {/* <Route path="/blog" element={<KuvugaBlogIndexPage />} /> */}
          <Route path="/pricing" element={<PricingPage />} />
          <Route path="/about" element={<AboutUsPage />} />
          <Route path="/login" element={<KuvugaLoginPage />} />
          <Route path="/stats" element={<KuvugaPasscodePage />} />
          <Route path="/kuvuga-stats" element={<CustomAnalyticsPage />} />
          <Route path="/register" element={<KuvugaRegisterPage />} />
          <Route path="/contact" element={<KuvugaContactUs />} />
        </Routes>
      </Router>
    </>
  );
}

// export default EventLandingPage;
// export default HotelTravelLandingPage;
// export default AgencyLandingPage;
// export default SaaSProductLandingPage;
// export default RestaurantLandingPage;
// export default ServiceLandingPage;
// export default HostingCloudLandingPage;

// export default LoginPage;
// export default SignupPage;
// export default PricingPage;
// export default AboutUsPage;
// export default ContactUsPage;
// export default BlogIndexPage;
// export default TermsOfServicePage;
// export default PrivacyPolicyPage;

// export default MainLandingPage;
