const QUESTIONS_TYPE = [
  // {
  //   key: "0",
  //   title: "Create Your Own Game",
  //   url: "game-setup",
  //   img: "https://images.unsplash.com/photo-1580582932707-520aed937b7b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1932&q=80",
  //   value: "custom",
  //   featured: "true",
  // },
  {
    key: "multiple",
    title: "Multiple Choice",
    url: "/multiple-choice",
    img: "https://images.unsplash.com/photo-1606326608606-aa0b62935f2b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80",
    value: "multiple",
  },
  {
    key: "fill",
    title: "Fill in the Blanks",
    url: "/fill-blanks",
    img: "https://images.unsplash.com/photo-1522542550221-31fd19575a2d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80",
    value: "fill",
  },
  {
    key: "boolean",
    title: "True / False",
    url: "/true-false",
    img: "https://images.unsplash.com/photo-1501163109389-abf37ca1276a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80",
    value: "truefalse",
  },
  // {
  //   key: "match",
  //   title: "Match",
  //   url: "",
  //   img: "https://images.unsplash.com/photo-1573867639040-6dd25fa5f597?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80",
  //   value: "match",
  // },
];

export default QUESTIONS_TYPE;
