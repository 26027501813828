import { v4 as uuidv4 } from "uuid";

// Hashing function (example: simple string to number hashing)
const hashCode = (str) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
  }
  return hash;
};

// Function to generate a unique identifier based on device/browser characteristics
const generateUniqueIdentifier = () => {
  let sessionSettingsObj = {};

  // Combine relevant characteristics (example: navigator information, screen resolution)
  const combinedInfo = `${navigator.userAgent}${window.screen.width}${window.screen.height}${window.screen.colorDepth}`;

  // Hash the combined information to create a unique identifier
  const hashedIdentifier = hashCode(combinedInfo);

  sessionSettingsObj = {
    kvg_setting_one: hashedIdentifier,
    kvg_setting_two: uuidv4(),
  };
  const sessionSettings = JSON.stringify(sessionSettingsObj);

  // return hashedIdentifier;
  return sessionSettings;
};

export default generateUniqueIdentifier;
