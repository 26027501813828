import { devMsgLogger, devValueLogger } from "utils/devLogger";
import { db } from "./db";

// check if word is in store
export const valueAlreadyExists = async (objectStoreName, key, value) => {
  const result = await db[objectStoreName]
    .where(`${key}`)
    .equalsIgnoreCase(`${value}`)
    .toArray();

  try {
    if (result.length > 0) {
      return result !== undefined;
    } else {
      devMsgLogger("word does not exist");
    }
  } catch (error) {}
};

// read from db
export const readFromDB = async (objectStoreName, key) => {
  try {
    const result = await db.transaction("r", db[objectStoreName], async () => {
      // Within the transaction, perform database read operations
      return db[objectStoreName].where(key).first();
    });

    if (result !== undefined) {
      devValueLogger(`Data found in ${objectStoreName}:`, result);
    } else {
      devValueLogger(`No data found in ${objectStoreName} for key:`, key);
    }
  } catch (error) {
    devValueLogger(`Error reading from the database: ${error}`);
  }
};

// write to db
export const writeToDB = async (objectStoreName, data) => {
  try {
    await db.transaction("rw", db[objectStoreName], async () => {
      // Within the transaction, perform database operations
      await db[objectStoreName].add(data);
    });

    devMsgLogger(`Data added to ${objectStoreName} successfully!`);
  } catch (error) {
    devMsgLogger(`Error writing to the database: ${error}`);
  }
};

// add card to favorites
export const addToFavorites = (word_id, word, category) => {
  valueAlreadyExists("userFavorites", "word", word).then((exists) => {
    if (exists) {
      devMsgLogger("exists");
      return;
    } else {
      db.userFavorites.add({ word_id, word, category });
    }
  });
};

// reomve from favorites
export const removeFromFavorites = (word_id, word, category) => {
  db.userFavorites.delete({ word_id, word, category });
};

// check if db has entries
export const getObjectStoreCount = async () => {
  const countRequest = await db.userFavorites.count();
  if (countRequest > 0) {
    devMsgLogger("IndexedDB has data.");
  } else {
    devMsgLogger("IndexedDB is empty.");
  }
};

// Add user progress
export const addUserLessonProgress = (category, section, activity) => {
  db.userLessonProgress.add({ category, section, activity, completed: false });
};

// Update user progress when a section is completed
export const markSectionCompleted = (category, section) => {
  db.userLessonProgress.update({ category, section }, { completed: true });
};
// Update user progress when a section is completed
export const markActivityCompleted = (category, section, activity) => {
  db.userLessonProgress.update(
    { category, section, activity },
    { completed: true }
  );
};

// Get completed sections for a specific lesson/category
export const getCompletedSectionsForLevel = (category) => {
  return db.userLessonProgress.where({ category, completed: true }).toArray();
};

export default {
  readFromDB,
  writeToDB,
  addUserLessonProgress,
  markSectionCompleted,
  markActivityCompleted,
  getCompletedSectionsForLevel,
};
