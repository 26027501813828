import React, { useEffect } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Header from "components/headers/secondaryLight.js";
import Pricing from "components/pricing/kuvuga-pricing/KuvugaTwoPlansWithDurationSwitcher.js";
import Footer from "components/footers/kuvuga-footers/KuvugaFiveColumnWithInputForm.js";
// import Testimonial from "components/testimonials/ThreeColumnWithProfileImage.js";
// import FAQ from "components/faqs/kuvuga-faqs/KuvugaSingleCol.js";

import { kuvugaPageView } from "utils/customAnalyticsUtils";

export default () => {
  // custom analytics
  useEffect(() => {
    kuvugaPageView();
  }, []);

  return (
    <AnimationRevealPage>
      <Header />
      <Pricing />
      {/* <Testimonial heading="Our Paying Customers" />
      <FAQ /> */}
      <Footer />
    </AnimationRevealPage>
  );
};
