// import React, { useState } from "react";
// import useSWR from "swr";
// import Slider from "react-slick";
// import tw from "twin.macro";
// import styled from "styled-components";
// import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons";
// import { ReactComponent as ChevronLeftIcon } from "feather-icons/dist/icons/chevron-left.svg";
// import { ReactComponent as ChevronRightIcon } from "feather-icons/dist/icons/chevron-right.svg";
// import { ReactComponent as EditIcon } from "feather-icons/dist/icons/edit.svg";
// import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";

// import LoopRoundedIcon from "@mui/icons-material/LoopRounded";
// import StarBorderRoundedIcon from "@mui/icons-material/StarBorderRounded";
// import StarRoundedIcon from "@mui/icons-material/StarRounded";

// import { useLocation, useNavigate } from "react-router-dom";
// // import Header from "../components/headers/secondaryLight";
// import AnimationRevealPage from "helpers/AnimationRevealPage";
// import EndLearningCard from "../components/cards/kuvuga-cards/EndLearningCard";
// // import { addToFavorites } from "../dexie/dbUtils";
// // import Skeleton from "@mui/material/Skeleton";
// import Button from "@mui/material/IconButton";
// import VolumeUpIcon from "@mui/icons-material/VolumeUp";
// import CircularProgress from "@mui/material/CircularProgress";

// //
// import playTheWord from "utils/audioUtils";
// import KuvugaBottomNav from "components/footers/kuvuga-footers/KuvugaBottomNav";
// import { Howl, Howler } from "howler";

// import ReactModalAdapter from "helpers/ReactModalAdapter";
// import db from "../dexie/db";
// import allLanguagesList from "../constants/allLanguagesList.json";
// import { devMsgLogger, devValueLogger } from "utils/devLogger";
// import { useSelector } from "react-redux";

// const Container = tw.div`relative`;
// const Content = tw.div`h-[86vh] lg:h-[92vh] mx-auto flex flex-col justify-around`;
// const Spinner = tw.div`h-[60vh] mt-[3rem] mx-auto flex justify-center items-center`;
// // const HeadingWithControl = tw.div`mt-4 flex flex-col items-center`;
// const Controls = tw.div`mb-4 flex justify-center`;
// const ControlButton = styled(PrimaryButtonBase)`
//   ${tw`my-4 mx-6 rounded-full p-2`}
//   svg {
//     ${tw`w-6 h-6`}
//   }
// `;
// const PrevButton = tw(ControlButton)``;
// const NextButton = tw(ControlButton)``;

// const CardSlider = styled(Slider)`
//   ${tw`mt-1 drop-shadow-xl`}
//   .slick-track {
//     ${tw`flex`}
//   }
//   .slick-slide {
//     ${tw`h-full flex justify-center`}
//   }
//   .button__bar {
//     ${tw`align-middle m-auto pt-6 text-center`}
//     li {
//       ${tw`list-none cursor-pointer inline-block mx-1 p-0`}
//     }
//     li button {
//       ${tw`border-none text-transparent bg-gray-600 block h-[5px] w-[5px] rounded-full`}
//     }
//     li.slick-active button {
//       ${tw`bg-primary-500`}
//     }
//   }
// `;
// const Card = tw.div`h-full flex! flex-col items-center rounded focus:outline-none bg-gray-100`;
// // const CardImage = styled.div((props) => [
// //   `background-image: url("${props.imageSrc}");`,
// //   tw`w-full h-56 sm:h-64 bg-cover bg-center rounded`,
// // ]);

// const WordSection = tw.div`h-[50vh] w-[80vw] lg:w-[60vw] flex flex-col items-center justify-start`;
// // const MainWordReviewContainer = tw.div`flex flex-col sm:flex-row sm:justify-between sm:items-center`;
// // const MainWord = tw.div`w-full text-3xl px-3 bg-gray-200 rounded-t-md font-bold flex flex-col justify-center items-center text-center capitalize drop-shadow-md rounded`;
// const MainWord = tw.div`w-full text-3xl px-3 bg-gray-200 rounded-t-md font-bold flex flex-col justify-center items-center text-center capitalize rounded`;

// // const SecondaryInfoContainer = tw.div`flex flex-col sm:flex-row mt-2 sm:mt-4`;
// const TranslatedWord = tw.div`text-2xl px-3 font-bold text-gray-800 capitalize my-auto text-center`;
// // const Comment = tw.p`text-center text-xl text-secondary-100 font-semibold`;

// //
// const CounterSpan = tw.span`font-bold my-auto mx-4`;
// const CardButtons = tw.div`mt-auto w-full rounded flex flex-row justify-between`;
// const CardButton = tw.button`mt-auto p-2 rounded text-primary-500 font-bold flex flex-row justify-between`;
// const CommentForm = tw.form`lg:w-[50em] mx-4 flex flex-col items-center rounded z-10 bg-gray-200`;
// const CommentMainWord = tw.div`w-full font-bold flex justify-center items-center`;
// const Input = tw.input`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`;
// const CommentTextarea = styled(Input).attrs({ as: "textarea" })`
//   ${tw`h-[20em] w-[100%] rounded p-2 bg-gray-300`}
// `;
// const CommentButtons = tw.div`mt-auto w-full rounded flex flex-row justify-end`;

// const StyledModal = styled(ReactModalAdapter)`
//   &.mainHeroModal__overlay {
//     ${tw`fixed h-[85vh] mt-8 lg:mt-10 lg:w-[60vw] lg:mx-auto inset-0 z-50`}
//   }
//   &.mainHeroModal__content {
//     ${tw`xl:mx-auto m-4 absolute inset-0 flex justify-center items-center rounded-lg bg-gray-200 outline-none`}
//   }
//   .content {
//     ${tw`w-full`}
//   }
// `;
// const CloseModalButton = tw.button`absolute top-0 right-0 mt-8 mr-8 hocus:text-primary-500`;

// const MainSpinner = () => {
//   return (
//     <Spinner>
//       <CircularProgress
//         sx={{
//           color: "#6415FF",
//         }}
//       />
//     </Spinner>
//   );
// };

// export default () => {
//   const navigate = useNavigate();
//   const location = useLocation();
//   const urlSearchValue = location.search.slice(10);
//   if (process.env.NODE_ENV?.toLowerCase().trim() === "development") {
//     console.log(location.pathname); // ex: learning
//     console.log(location.search); // ex: ?categories=basics
//     console.log("search value: ", urlSearchValue);
//   }

//   const [flipped, setFlipped] = useState(false);
//   const [isCompleted, setIsCompleted] = useState(false);
//   // const [rotated, setRotated] = useState(null);
//   const [currentIndex, setCurrentIndex] = useState(0);
//   // const [audioData, setAudioData] = useState(null);
//   const [lanSettings, setLanSettings] = useState({
//     languageUserKnowns: "",
//     languageUserIsLearning: "",
//   });

//   const [favorited, setFavorited] = useState(false);
//   const [openCommenting, setOpenCommenting] = useState(false);
//   const handleSubmit = async (e) => {
//     e.preventDefault();
//   };
//   const handleModalClose = () => {
//     setOpenCommenting(!openCommenting);
//   };

//   // useState is used instead of useRef below because we want to re-render when sliderRef becomes available (not null)
//   const [sliderRef, setSliderRef] = useState(null);
//   const sliderSettings = {
//     arrows: false,
//     dots: true,
//     dotsClass: "button__bar",
//     slidesToShow: 1,
//     slidesToScroll: 1,
//     // infinite: false,
//     // responsive: [
//     //   {
//     //     breakpoint: 1280,
//     //     settings: {
//     //       slidesToShow: 1,
//     //     },
//     //   },

//     //   {
//     //     breakpoint: 900,
//     //     settings: {
//     //       slidesToShow: 1,
//     //     },
//     //   },
//     // ],
//   };

//   const getLearningLanguage = useSelector(
//     (state) => state.languageSetupConfig
//   );

//   devValueLogger(
//     "LAN SETTINGS",
//     JSON.stringify(getLearningLanguage)
//   );

//   // // PROD: fetch data
//   const url =
//     process.env.NODE_ENV?.toLowerCase().trim() === "production"
//       ? `${process.env.REACT_APP_SERVER_PROD_URL}/api/v1/words?tag=${urlSearchValue}&apiKey=${process.env.REACT_APP_FREE_TRIAL_API_KEY}`
//       : `${process.env.REACT_APP_SERVER_DEV_URL}/api/v1/words?tag=${urlSearchValue}&apiKey=${process.env.REACT_APP_FREE_TRIAL_API_KEY}`;

//   const fetchData = async () => {
//     //
//     const setknown = localStorage.getItem("fluent-language").toLowerCase();
//     const setLearn = localStorage.getItem("learning-language").toLowerCase();

//     setLanSettings({
//       languageUserKnowns: `${allLanguagesList[setknown]}`,
//       languageUserIsLearning: `${allLanguagesList[setLearn]}`,
//     });

//     // get gata
//     try {
//       const response = await fetch(url);
//       const data = await response.json();
//       return data.words;
//     } catch (error) {
//       devMsgLogger(`Fetch error: ${error}`);
//     }
//   };

//   //
//   const { data, error } = useSWR(url, fetchData);

//   // Render loading state
//   if (!data && !error) {
//     return <MainSpinner />;
//   }

//   // Render error state
//   if (error) {
//     return <MainSpinner />;
//   }

//   //
//   const handleFlipCard = () => {
//     // flipped ? setFlipped(false) : setFlipped(true);
//     setFlipped(!flipped);
//     // setRotated(!rotated);
//   };

//   const handleRepeat = () => {
//     navigate(0);
//   };

//   const handleFavorited = (fav, cat) => {
//     setFavorited(!favorited);
//     db.userFavorites.add(fav, cat);
//   };

//   const handleAudioPlay = (audioFileName) => {
//     const audioUrl =
//       process.env.NODE_ENV?.toLowerCase().trim() === "production"
//         ? `${process.env.REACT_APP_SERVER_PROD_URL}/api/v1/audio?audioWord=${audioFileName}&apiKey=${process.env.REACT_APP_FREE_TRIAL_API_KEY}`
//         : `${process.env.REACT_APP_SERVER_DEV_URL}/api/v1/audio?audioWord=${audioFileName}&apiKey=${process.env.REACT_APP_FREE_TRIAL_API_KEY}`;
//     const sound = new Howl({
//       src: [audioUrl],
//       format: ["m4a"],
//       preload: true,
//       volume: 0.5,
//     });
//     Howler.autoUnlock = false;
//     sound.play();
//   };

//   //
//   return (
//     <AnimationRevealPage disabled>
//       <Container>
//         {/* <Header /> */}
//         {}
//         {isCompleted && <EndLearningCard handleRepeat={handleRepeat} />}
//         {/* {!isCompleted && openCommenting && (
//           <CommentForm type="submit" onSubmit={handleSubmit}>
//             <CommentMainWord>How Can We Improve this Card?</CommentMainWord>
//             <ModalDivider />
//             <CommentTextarea
//               name="comments"
//               placeholder="Comments Here"
//             ></CommentTextarea>
//             <CommentButtons>
//               <CardButton onClick={() => setOpenCommenting(false)}>
//                 Cancel
//               </CardButton>
//               <CardButton onSubmit={handleSubmit}>Submit</CardButton>
//             </CommentButtons>
//           </CommentForm>
//         )} */}

//         {!isCompleted && !error && data.length !== 0 && (
//           <Content>
//             <CardSlider
//               beforeChange={(currentSlide, nextSlide) => {
//                 setCurrentIndex(nextSlide);
//               }}
//               ref={setSliderRef}
//               {...sliderSettings}
//             >
//               {data.map((card, index) => (
//                 <Card id="card-id" key={index}>
//                   {/* <CardImage
//                     imageSrc={`https://images.unsplash.com/photo-1549294413-26f195200c16?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&h=1024&w=768&q=80`}
//                   /> */}
//                   <WordSection id="word-section-id">
//                     {/* <MainWordReviewContainer> */}
//                     <MainWord
//                       id="main-word-id"
//                       style={
//                         location.pathname === "/reviewing"
//                           ? { height: "100%" }
//                           : { height: "70%" }
//                       }
//                     >
//                       {/* {flipped ? card.eng_word_sing : card.kiny_word_sing} */}
//                       {flipped
//                         ? card[`${lanSettings?.languageUserKnowns}_word_sing`]
//                         : card[
//                             `${lanSettings?.languageUserIsLearning}_word_sing`
//                           ]}

//                       <Button
//                         variant="contained"
//                         id="audio-id"
//                         sx={{ color: "#6415FF" }}
//                         onClick={
//                           flipped
//                             ? () =>
//                                 playTheWord(
//                                   card[
//                                     `${lanSettings?.languageUserKnowns}_word_sing`
//                                   ]
//                                 )
//                             : () =>
//                                 handleAudioPlay(
//                                   card[
//                                     `${lanSettings?.languageUserIsLearning}_word_sing`
//                                   ]
//                                 )
//                         }
//                       >
//                         <VolumeUpIcon />
//                         {/* {audioData && <audio src={audioData} autoPlay />} */}
//                       </Button>
//                     </MainWord>

//                     {location.pathname === "/reviewing" ? (
//                       <CardButtons>
//                         <CardButton
//                           id="edit-button-id"
//                           onClick={() => setOpenCommenting(true)}
//                         >
//                           <EditIcon />
//                         </CardButton>
//                         <CardButton
//                           id="flip-button-id"
//                           onClick={handleFlipCard}
//                         >
//                           <LoopRoundedIcon />
//                         </CardButton>
//                         <CardButton
//                           id="favorietd-button-id"
//                           onClick={() =>
//                             handleFavorited(card.id, urlSearchValue)
//                           }
//                         >
//                           {favorited ? (
//                             <StarRoundedIcon />
//                           ) : (
//                             <StarBorderRoundedIcon />
//                           )}
//                         </CardButton>
//                       </CardButtons>
//                     ) : null}
//                     {/* <RatingsInfo>
//                     <StarIcon />
//                     <Rating>{card.rating}</Rating>
//                   </RatingsInfo> */}
//                     {/* </MainWordReviewContainer> */}
//                     {/* <SecondaryInfoContainer> */}
//                     {/* <IconWithText> */}
//                     {/* <IconContainer>
//                       <LocationIcon />
//                     </IconContainer> */}
//                     {/* <Text>{card.locationText}</Text> */}
//                     {/* </IconWithText>
//                   <IconWithText>
//                     <IconContainer>
//                       <PriceIcon />
//                     </IconContainer> */}
//                     {/* <Text>{card.pricingText}</Text> */}
//                     {/* </IconWithText> */}
//                     {/* </SecondaryInfoContainer> */}
//                     {location.pathname === "/learning" && (
//                       <>
//                         <TranslatedWord id="translated-word-id">
//                           {/* {flipped ? card.kiny_word_sing : card.eng_word_sing} */}
//                           {flipped
//                             ? card[
//                                 `${lanSettings?.languageUserIsLearning}_word_sing`
//                               ]
//                             : card[
//                                 `${lanSettings?.languageUserKnowns}_word_sing`
//                               ]}
//                         </TranslatedWord>
//                         {/* <Comment>{card.description}</Comment> */}
//                       </>
//                     )}
//                   </WordSection>
//                   {location.pathname === "/learning" ? (
//                     <CardButtons>
//                       <CardButton onClick={() => setOpenCommenting(true)}>
//                         <EditIcon />
//                       </CardButton>
//                       <CardButton onClick={handleFlipCard}>
//                         <LoopRoundedIcon />
//                       </CardButton>
//                       <CardButton onClick={() => setFavorited(!favorited)}>
//                         {favorited ? (
//                           <StarRoundedIcon />
//                         ) : (
//                           <StarBorderRoundedIcon />
//                         )}
//                       </CardButton>
//                     </CardButtons>
//                   ) : null}
//                 </Card>
//               ))}
//             </CardSlider>
//             <Controls>
//               <PrevButton
//                 onClick={() => {
//                   sliderRef?.slickPrev();
//                 }}
//               >
//                 <ChevronLeftIcon />
//               </PrevButton>
//               <CounterSpan>
//                 {currentIndex + 1} / {data.length}
//               </CounterSpan>
//               <NextButton
//                 onClick={() => {
//                   if (currentIndex === data.length - 1) {
//                     // return 0;
//                     setIsCompleted(true);
//                     return;
//                   }
//                   sliderRef?.slickNext();
//                 }}
//               >
//                 <ChevronRightIcon />
//               </NextButton>
//             </Controls>
//           </Content>
//         )}
//         <KuvugaBottomNav />

//         {openCommenting && (
//           <StyledModal
//             closeTimeoutMS={300}
//             className="mainHeroModal"
//             isOpen={openCommenting}
//             onRequestClose={handleModalClose}
//             shouldCloseOnOverlayClick={true}
//           >
//             <CloseModalButton onClick={handleModalClose}>
//               <CloseIcon tw="w-6 h-6" />
//             </CloseModalButton>
//             <div className="content">
//               <CommentForm type="submit" onSubmit={handleSubmit}>
//                 <CommentMainWord>How Can We Improve This Card?</CommentMainWord>
//                 <CommentTextarea
//                   name="comments"
//                   placeholder="Comments Here"
//                 ></CommentTextarea>
//                 <CommentButtons>
//                   <CardButton
//                     onClick={(e) => {
//                       e.preventDefault();
//                       setOpenCommenting(false);
//                     }}
//                   >
//                     Cancel
//                   </CardButton>
//                   <CardButton type="submit" onSubmit={handleSubmit}>
//                     Submit
//                   </CardButton>
//                 </CommentButtons>
//               </CommentForm>
//             </div>
//           </StyledModal>
//         )}
//       </Container>
//     </AnimationRevealPage>
//   );
// };

import React, { useState, useEffect, useCallback, useRef } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import Slider from "react-slick";
import tw from "twin.macro";
import styled from "styled-components";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons";
import { ReactComponent as ChevronLeftIcon } from "feather-icons/dist/icons/chevron-left.svg";
import { ReactComponent as ChevronRightIcon } from "feather-icons/dist/icons/chevron-right.svg";
import { ReactComponent as EditIcon } from "feather-icons/dist/icons/edit.svg";
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";

import LoopRoundedIcon from "@mui/icons-material/LoopRounded";
import StarBorderRoundedIcon from "@mui/icons-material/StarBorderRounded";
import StarRoundedIcon from "@mui/icons-material/StarRounded";

import { useLocation, useNavigate } from "react-router-dom";
// import Header from "../components/headers/secondaryLight";
import AnimationRevealPage from "helpers/AnimationRevealPage";
import EndLearningCard from "../components/cards/kuvuga-cards/EndLearningCard";

// import Skeleton from "@mui/material/Skeleton";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/IconButton";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import CircularProgress from "@mui/material/CircularProgress";

//
import playTheWord from "utils/audioUtils";
import KuvugaBottomNav from "components/footers/kuvuga-footers/KuvugaBottomNav";
import { Howl, Howler } from "howler";

import allTopics from "../constants/allTopics.json";
import { getElementsByIndices } from "utils";

import ReactModalAdapter from "helpers/ReactModalAdapter";
import { addToFavorites, markActivityCompleted } from "../dexie/dbUtils";
import { devMsgLogger, devValueLogger } from "utils/devLogger";

// analytics 
import { kuvugaPageView } from "utils/customAnalyticsUtils";

const Container = tw.div`relative`;
const Content = tw.div`h-[86vh] lg:h-[92vh] mx-auto flex flex-col justify-around`;

// const HeadingWithControl = tw.div`mt-4 flex flex-col items-center`;
const Controls = tw.div`mb-4 flex justify-center`;
const ControlButton = styled(PrimaryButtonBase)`
  ${tw`my-4 mx-6 rounded-full p-2`}
  svg {
    ${tw`w-6 h-6`}
  }
`;
const PrevButton = tw(ControlButton)``;
const NextButton = tw(ControlButton)``;

const CardSlider = styled(Slider)`
  ${tw`mt-1 drop-shadow-xl`}
  .slick-track {
    ${tw`flex`}
  }
  .slick-slide {
    ${tw`h-full flex justify-center`}
  }
  .button__bar {
    ${tw`align-middle m-auto pt-6 text-center`}
    li {
      ${tw`list-none cursor-pointer inline-block mx-1 p-0`}
    }
    li button {
      ${tw`border-none text-transparent bg-gray-600 block h-[5px] w-[5px] rounded-full`}
    }
    li.slick-active button {
      ${tw`bg-primary-500`}
    }
  }
`;
const Card = tw.div`h-full flex! flex-col items-center rounded focus:outline-none bg-gray-100`;
// const CardImage = styled.div((props) => [
//   `background-image: url("${props.imageSrc}");`,
//   tw`w-full h-56 sm:h-64 bg-cover bg-center rounded`,
// ]);

const WordSection = tw.div`h-[50vh] w-[80vw] lg:w-[60vw]  flex flex-col items-center justify-start`;
// const MainWordReviewContainer = tw.div`flex flex-col sm:flex-row sm:justify-between sm:items-center`;
// const MainWord = tw.div`w-full text-3xl px-3 bg-gray-200 rounded-t-md font-bold flex flex-col justify-center items-center text-center capitalize drop-shadow-md rounded`;
const MainWord = tw.div`w-full text-3xl px-3 bg-gray-200 rounded-t-md font-bold flex flex-col justify-center items-center text-center capitalize rounded`;

// const SecondaryInfoContainer = tw.div`flex flex-col sm:flex-row mt-2 sm:mt-4`;
const TranslatedWord = tw.div`text-2xl px-3 font-bold text-gray-800 capitalize my-auto text-center`;
// const Comment = tw.p`text-center text-xl text-secondary-100 font-semibold`;

//
const CounterSpan = tw.span`font-bold my-auto mx-4`;
const CardButtons = tw.div`mt-auto w-full rounded flex flex-row justify-between`;
const CardButton = tw.button`mt-auto p-2 rounded text-primary-500 font-bold flex flex-row justify-between`;
const CommentForm = tw.form`lg:w-[50em] mx-4 flex flex-col items-center rounded z-10 bg-gray-200`;
const CommentMainWord = tw.div`w-full font-bold flex justify-center items-center`;
const Input = tw.input`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`;
const CommentTextarea = styled(Input).attrs({ as: "textarea" })`
  ${tw`h-[20em] w-[100%] rounded p-2 bg-gray-300`}
`;
const CommentButtons = tw.div`mt-auto w-full rounded flex flex-row justify-end`;

const StyledModal = styled(ReactModalAdapter)`
  &.mainHeroModal__overlay {
    ${tw`fixed h-[85vh] mt-8 lg:mt-10 lg:w-[60vw] lg:mx-auto inset-0 z-50`}
  }
  &.mainHeroModal__content {
    ${tw`xl:mx-auto m-4 absolute inset-0 flex justify-center items-center rounded-lg bg-gray-200 outline-none`}
  }
  .content {
    ${tw`w-full`}
  }
`;
const CloseModalButton = tw.button`absolute top-0 right-0 mt-8 mr-8 hocus:text-primary-500`;

export default () => {
  // custom analytics
  useEffect(() => {
    kuvugaPageView();
  }, []);

  //
  const navigate = useNavigate();
  const location = useLocation();
  // const urlSearchValue = location.search.slice(10);
  // if (process.env.NODE_ENV?.toLowerCase().trim() === "development") {
  //   console.log('pathaname: ', location.pathname); // ex: learning
  //   console.log('path query: ', location.search); // ex: ?categories=basics
  //   console.log("search value: ", urlSearchValue);
  // }

  const getLearningLanguage = useSelector(
    (state) => state?.languageSetupConfig
  );

  devMsgLogger(getLearningLanguage);

  const [sectionInfo, setSectionInfo] = useState({});
  const [wordsData, setWordsData] = useState([]);
  const [flipped, setFlipped] = useState(false);
  // const [cardIndex, setCardIndex] = useState(0);
  const [isCompleted, setIsCompleted] = useState(false);
  // const [rotated, setRotated] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  // const [audioData, setAudioData] = useState(null);

  const [favorited, setFavorited] = useState(false);
  const [openCommenting, setOpenCommenting] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
  };
  const handleModalClose = () => {
    setOpenCommenting(!openCommenting);
  };

  // useState is used instead of useRef below because we want to re-render when sliderRef becomes available (not null)
  const [sliderRef, setSliderRef] = useState(null);
  const sliderSettings = {
    arrows: false,
    dots: true,
    dotsClass: "button__bar",
    slidesToShow: 1,
    slidesToScroll: 1,
    // infinite: false,
    // responsive: [
    //   {
    //     breakpoint: 1280,
    //     settings: {
    //       slidesToShow: 1,
    //     },
    //   },

    //   {
    //     breakpoint: 900,
    //     settings: {
    //       slidesToShow: 1,
    //     },
    //   },
    // ],
  };

  useEffect(() => {
    const query = location.search;
    const queryObject = {};

    const searchParams = new URLSearchParams(query);
    for (const [key, value] of searchParams.entries()) {
      queryObject[`${key}`] = `${value}`;
    }
    if (queryObject.section !== "") {
      queryObject.num = queryObject.section;
    }
    setSectionInfo(queryObject);
  }, [location.search]);

  devValueLogger("query params", sectionInfo);

  // get section object; filter will return an array with on object
  let sectionIndicesRef = useRef({});
  useEffect(() => {
    // let sectionObj = [];
    if (
      sectionInfo.section &&
      sectionInfo.section !== "" &&
      Object.keys(sectionIndicesRef.current).length === 0
    ) {
      const sectionObj = allTopics.topics.filter((topic) => {
        return topic.topicTitle === sectionInfo.category;
      });

      sectionIndicesRef.current = sectionObj[0]?.indices;
      devValueLogger("Indices", sectionIndicesRef.current);
    }
  }, [sectionInfo.category, sectionInfo.section]);

  // PROD: fetch data
  useEffect(() => {
    async function fetchData() {
      axios
        .get(
          process.env.NODE_ENV?.toLowerCase().trim() === "production"
            ? `${process.env.REACT_APP_SERVER_PROD_URL}/api/v1/words?tag=${sectionInfo.category}&apiKey=${process.env.REACT_APP_FREE_TRIAL_API_KEY}`
            : `${process.env.REACT_APP_SERVER_DEV_URL}/api/v1/words?tag=${sectionInfo.category}&apiKey=${process.env.REACT_APP_FREE_TRIAL_API_KEY}`,
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "applicaton/json",
            },
          }
        )
        .then((res) => {
          if (sectionInfo.section && sectionInfo.section !== "") {
            setWordsData(
              getElementsByIndices(
                sectionIndicesRef.current[`section ${sectionInfo.section}`],
                res.data?.words
              )
            );
          } else {
            setWordsData(res?.data?.words);
          }
        })
        .catch((error) => {
          devValueLogger("fetch function error", error);
        });
    }

    //
    fetchData();
  }, [sectionInfo.category, sectionInfo.section]);

  const handleFlipCard = () => {
    // flipped ? setFlipped(false) : setFlipped(true);
    setFlipped(!flipped);
    // setRotated(!rotated);
  };

  const handleRepeat = () => {
    navigate(0);
  };

  const handleFavorited = (id, fav, cat) => {
    setFavorited(!favorited);
    addToFavorites(id, fav, cat);
  };

  // const rotateStyle =
  //   rotated && location.pathname === "/reviewing"
  //     ? {
  //         transform: "rotateY(180deg)",
  //         transition: "1s",
  //       }
  //     : {
  //         transform: "rotateY(0deg)",
  //         transition: "1s",
  //       };

  const handleAudioPlay = useCallback(async (audioFileName) => {
    const audioUrl =
      process.env.NODE_ENV?.toLowerCase().trim() === "production"
        ? `${process.env.REACT_APP_SERVER_PROD_URL}/api/v1/audio?audioWord=${audioFileName}&apiKey=${process.env.REACT_APP_FREE_TRIAL_API_KEY}`
        : `${process.env.REACT_APP_SERVER_DEV_URL}/api/v1/audio?audioWord=${audioFileName}&apiKey=${process.env.REACT_APP_FREE_TRIAL_API_KEY}`;
    const sound = new Howl({
      src: [audioUrl],
      format: ["m4a"],
      preload: true,
      volume: 0.5,
    });
    Howler.autoUnlock = false;
    sound.play();
  }, []);

  return (
    <AnimationRevealPage disabled>
      <Container id="main-container">
        {/* <Header /> */}
        {wordsData.length === 0 && (
          <Paper
            elevation={3}
            sx={{
              height: "60vh",
              width: "80vw",
              margin: "5vh auto",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#edf2f7",
            }}
          >
            <CircularProgress
              sx={{
                color: "#6415FF",
              }}
            />
          </Paper>
        )}
        {isCompleted && <EndLearningCard handleRepeat={handleRepeat} />}

        {!isCompleted && wordsData.length !== 0 && (
          <Content id="main-content">
            <CardSlider
              beforeChange={(currentSlide, nextSlide) => {
                setCurrentIndex(nextSlide);
              }}
              ref={setSliderRef}
              {...sliderSettings}
            >
              {wordsData.map((card, index) => (
                <Card id="card-id" key={index}>
                  {/* <CardImage
                    imageSrc={`https://images.unsplash.com/photo-1549294413-26f195200c16?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&h=1024&w=768&q=80`}
                  /> */}
                  <WordSection id="word-section-id">
                    {/* <MainWordReviewContainer> */}
                    <MainWord
                      id="main-word-id"
                      style={
                        location.pathname === "/reviewing"
                          ? { height: "100%" }
                          : { height: "70%" }
                      }
                    >
                      {flipped ? card.eng_word_sing : card.kiny_word_sing}

                      <Button
                        variant="contained"
                        id="audio-id"
                        sx={{ color: "#6415FF" }}
                        onClick={
                          flipped
                            ? () => playTheWord(card.eng_word_sing)
                            : () => handleAudioPlay(card.kiny_word_sing)
                        }
                      >
                        <VolumeUpIcon />
                        {/* {audioData && <audio src={audioData} autoPlay />} */}
                      </Button>
                    </MainWord>

                    {location.pathname === "/reviewing" ? (
                      <CardButtons>
                        <CardButton
                          id="edit-button-id"
                          onClick={() => setOpenCommenting(true)}
                        >
                          <EditIcon />
                        </CardButton>
                        <CardButton
                          id="flip-button-id"
                          onClick={handleFlipCard}
                        >
                          <LoopRoundedIcon />
                        </CardButton>
                        <CardButton
                          id="favorietd-button-id"
                          onClick={() =>
                            handleFavorited(
                              card.id,
                              card.eng_word_sing,
                              sectionInfo.category
                            )
                          }
                        >
                          {favorited ? (
                            <StarRoundedIcon />
                          ) : (
                            <StarBorderRoundedIcon />
                          )}
                        </CardButton>
                      </CardButtons>
                    ) : null}
                    {/* <RatingsInfo>
                    <StarIcon />
                    <Rating>{card.rating}</Rating>
                  </RatingsInfo> */}
                    {/* </MainWordReviewContainer> */}
                    {/* <SecondaryInfoContainer> */}
                    {/* <IconWithText> */}
                    {/* <IconContainer>
                      <LocationIcon />
                    </IconContainer> */}
                    {/* <Text>{card.locationText}</Text> */}
                    {/* </IconWithText>
                  <IconWithText>
                    <IconContainer>
                      <PriceIcon />
                    </IconContainer> */}
                    {/* <Text>{card.pricingText}</Text> */}
                    {/* </IconWithText> */}
                    {/* </SecondaryInfoContainer> */}
                    {location.pathname === "/learning" && (
                      <>
                        <TranslatedWord id="translated-word-id">
                          {flipped ? card.kiny_word_sing : card.eng_word_sing}
                        </TranslatedWord>
                        {/* <Comment>{card.description}</Comment> */}
                      </>
                    )}
                  </WordSection>
                  {location.pathname === "/learning" ? (
                    <CardButtons>
                      <CardButton onClick={() => setOpenCommenting(true)}>
                        <EditIcon />
                      </CardButton>
                      <CardButton onClick={handleFlipCard}>
                        <LoopRoundedIcon />
                      </CardButton>
                      <CardButton
                        onClick={() =>
                          handleFavorited(
                            card.id,
                            card.eng_word_sing,
                            sectionInfo.category
                          )
                        }
                      >
                        {favorited ? (
                          <StarRoundedIcon />
                        ) : (
                          <StarBorderRoundedIcon />
                        )}
                      </CardButton>
                    </CardButtons>
                  ) : null}
                </Card>
              ))}
            </CardSlider>
            {/* <HeadingWithControl> */}
            <Controls>
              <PrevButton
                onClick={() => {
                  sliderRef?.slickPrev();
                }}
              >
                <ChevronLeftIcon />
              </PrevButton>
              <CounterSpan>
                {currentIndex + 1} / {wordsData.length}
              </CounterSpan>
              <NextButton
                onClick={() => {
                  if (currentIndex === wordsData.length - 1) {
                    if (location.pathname === "/learning") {
                      markActivityCompleted(
                        sectionInfo.category,
                        sectionInfo.section,
                        "vocabulary"
                      );
                    } else if (location.pathname === "/reviewing") {
                      markActivityCompleted(
                        sectionInfo.category,
                        sectionInfo.section,
                        "review"
                      );
                    } 
                    // else if (location.pathname === "/multiple-choice") {
                    //   markActivityCompleted(
                    //     sectionInfo.category,
                    //     sectionInfo.section,
                    //     "practice"
                    //   );
                    // }

                    setIsCompleted(true);
                    // return;
                  }
                  sliderRef?.slickNext();
                }}
              >
                <ChevronRightIcon />
              </NextButton>
            </Controls>
          </Content>
        )}
        <KuvugaBottomNav />

        {openCommenting && (
          <StyledModal
            closeTimeoutMS={300}
            className="mainHeroModal"
            isOpen={openCommenting}
            onRequestClose={handleModalClose}
            shouldCloseOnOverlayClick={true}
          >
            <CloseModalButton onClick={handleModalClose}>
              <CloseIcon tw="w-6 h-6" />
            </CloseModalButton>
            <div className="content">
              <CommentForm type="submit" onSubmit={handleSubmit}>
                <CommentMainWord>How Can We Improve This Card?</CommentMainWord>
                <CommentTextarea
                  name="comments"
                  placeholder="Comments Here"
                ></CommentTextarea>
                <CommentButtons>
                  <CardButton
                    onClick={(e) => {
                      e.preventDefault();
                      setOpenCommenting(false);
                    }}
                  >
                    Cancel
                  </CardButton>
                  <CardButton type="submit" onSubmit={handleSubmit}>
                    Submit
                  </CardButton>
                </CommentButtons>
              </CommentForm>
            </div>
          </StyledModal>
        )}
      </Container>
    </AnimationRevealPage>
  );
};
