import React, { useEffect, useState } from "react";
import { Container, ContentWithPaddingMd } from "components/misc/Layouts";
import tw from "twin.macro";
import Header from "components/headers/secondaryLight.js";
import { PrimaryButton } from "components/misc/Buttons";
import { useNavigate } from "react-router-dom";

// import allLanguagesList from "../constants/allLanguagesList.json";
// import KuvugaDropdown from "../components/misc/KuvugaDropdown";


// analytics
import { kuvugaPageView } from "utils/customAnalyticsUtils";

// install 
import A2HSComponent from "lib/A2HSComponent";

// redux
// import { useDispatch, useSelector } from "react-redux";
// import { setLanguageSetupConfig } from "redux/slices/languageSetupSlice";

// components
const Layout = tw.div`bg-gray-100 font-display min-h-screen text-secondary-500 p-8 overflow-hidden`;
const Posts = tw.div`mt-4 md:mx-0 lg:-mr-8 flex flex-col justify-center items-center`;
const Paragraph = tw.p`mt-10 font-black text-center text-2xl md:text-4xl leading-snug max-w-2xl`;
const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
const ActivityButton = tw(
  PrimaryButton
)`w-[65vw] md:w-[55vw] lg:w-[45vw] mb-2 mx-1 capitalize`;

// main comp
export default () => {
  // const dispatch = useDispatch();
  const navigate = useNavigate();  
  
  // custom analytics
  useEffect(() => {
    kuvugaPageView();
  }, []);

  // install to home screen// snackbar
  const [isVisible, setIsVisible] = useState(true);
  const handleClose = () => {
    setIsVisible(false);
  };

  const formalObj = [{ activity: "Lessons", path: "lessons" }];

  const informalObj = [
    { activity: "Learning", path: "learn-categories" },
    { activity: "Reviewing", path: "review-categories" },
    { activity: "Comprehension", path: "review-categories" },
    { activity: "Practice", path: "game-categories" },
  ];

  // const getLearningLanguage = useSelector(
  //   (state) => state?.languageSetupConfig
  // );
  // const setLearningLanguage = (selectedLanguage) => {
  //   if (!langNameArr.includes(getLearningLanguage.knownLanguageState)) {
  //     setErrorState(true);
  //     return;
  //   }

  //   // add to local storage
  //   localStorage.setItem("learningLanguage", `${selectedLanguage}`)

  //   // add to redux store
  //   dispatch(
  //     setLanguageSetupConfig({
  //       learningLanguageState: selectedLanguage.toLowerCase(),
  //     })
  //   );
  //   navigate("/activities");
  // };

  return (
    <Layout>
      <Header />
      <Container>
        <ContentWithPaddingMd>
          <Paragraph>
            <HighlightedText>Formal</HighlightedText> Learning
          </Paragraph>
          <Posts>
            {formalObj?.map((item, index) => (
              <ActivityButton
                key={index}
                onClick={() => navigate(`/${item.path}`)}
              >
                {item.activity}
              </ActivityButton>
            ))}
          </Posts>

          <Paragraph>
            <HighlightedText>Informal</HighlightedText> Learning
          </Paragraph>
          <Posts>
            {informalObj?.map((item, index) => (
              <ActivityButton
                key={index}
                onClick={() => navigate(`/${item.path}`)}
              >
                {item.activity}
              </ActivityButton>
            ))}
          </Posts>
        </ContentWithPaddingMd>
      </Container>
      {isVisible && <A2HSComponent handleClose={handleClose} />}
    </Layout>
  );
};
