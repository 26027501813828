import React, { useRef } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { useNavigate } from "react-router-dom";
import KuvugaBottomNav from "components/footers/kuvuga-footers/KuvugaBottomNav";

const Content = styled.div`
  ${tw`h-2/3 w-full flex justify-center`}
`;

const Card = styled.div`
  ${tw`w-full lg:w-1/2`}
`;
const CardText = tw.div`h-full w-full flex flex-col justify-between capitalize`;
const CardTitle = tw.h1`h-[20%] w-full text-3xl font-bold flex justify-center items-center bg-gray-200 rounded-t-sm`;
const ScoreSection = tw.div`h-[60%] text-2xl font-bold flex flex-col justify-center items-center bg-gray-100 capitalize`;
const CardAction = tw(PrimaryButtonBase)`w-full mt-1`;

export default ({ handleRepeat, score, numQuestions }) => {
  const navigate = useNavigate();

  const goodScoreArr = [
    "Well Done!",
    "Way To Go!",
    "Nice Work!",
    "Outstanding!",
    "Fantastic!",
    "Keep It Up",
    "Great Start",
  ];

  const badScoreArr = [
    "Keep Going!",
    "Keep It Up!",
    "You Got This!",
    "Keep Pushing!",
    "You Can Do It!",
    "Keep It Up",
  ];

  const goodScoreRef = useRef(Math.floor(Math.random() * goodScoreArr.length));
  const badScoreRef = useRef(Math.floor(Math.random() * badScoreArr.length));

  const getScoreMessage = (score) => {
    let scorePercentage = Math.round((score * 100) / numQuestions);
    if (scorePercentage >= 90 && scorePercentage <= 100) {
      return goodScoreArr[goodScoreRef.current];
    } else if (scorePercentage >= 80 && scorePercentage < 90) {
      return goodScoreArr[goodScoreRef.current];
    } else if (scorePercentage >= 70 && scorePercentage < 80) {
      return goodScoreArr[goodScoreRef.current];
    } else if (scorePercentage >= 60 && scorePercentage < 70) {
      return badScoreArr[badScoreRef.current];
    } else if (scorePercentage >= 0 && scorePercentage < 60) {
      return badScoreArr[badScoreRef.current];
    } else {
      return "Game On!";
    }
  };

  return (
    <>
      <Content>
        <Card id="card">
          <CardText id="card-text">
            <CardTitle id="card-title">{getScoreMessage(score)}</CardTitle>
            <ScoreSection>
              <p>
                SCORE : {score} / {numQuestions}
              </p>
              <p>
                {numQuestions === 0
                  ? "0"
                  : Math.round((score * 100) / numQuestions)}
                %
              </p>
            </ScoreSection>
            <CardAction onClick={handleRepeat}>REPEAT</CardAction>
            <CardAction onClick={() => navigate("/game-categories")}>
              CHANGE GAME
            </CardAction>
          </CardText>
        </Card>
      </Content>
      <KuvugaBottomNav />
    </>
  );
};
