// Import Dexie.js
import Dexie from "dexie";

// Define your database
export const db = new Dexie("UserDatabase");

// Define the schema
db.version(1).stores({
  userSettings: "++id, kvg_setting_one, kvg_setting_two, pref_theme",
  userLanSettings: "++id, learning_language, fluent_language",
  userLessonProgress: "++id, category, section, activity, completed",
  userFavorites: "++id, word_id, word, category",
});

export default { db };
