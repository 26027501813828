import Dexie from "dexie";
import { devMsgLogger } from "utils/devLogger";

// Function to check if the database exists and create it if not
const initializeDatabase = async (databaseName) => {
  try {
    // Check if the database exists
    const exists = await Dexie.exists(databaseName);

    if (exists) {
      devMsgLogger(`Database "${databaseName}" exists.`);
    } else {
        devMsgLogger(`Database "${databaseName}" does not exist. Creating...`);

      // Create a new database
      const db = new Dexie(databaseName);

      // Define the schema or other configurations
      db.version(1).stores({
        userSettings: "++id, kvg_setting_one, kvg, setting_two, pref_theme",
        userLanSettings: "++id, learning_language, fluent_language",
        userLessonProgress: "++id, category, section, activity, completed",
        userFavorites: "++id, word_id, word, category",
      });

      devMsgLogger(`Database "${databaseName}" created successfully.`);
    }
  } catch (error) {
    devMsgLogger(`Error initializing database: ${error}`);
  }
};

export default initializeDatabase;
