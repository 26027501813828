import React, { useEffect, useState } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { ContentWithPaddingMd } from "components/misc/Layouts";
import tw from "twin.macro";
import { motion } from "framer-motion";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

import Header from "components/headers/secondaryLight.js";
import { useLocation, useNavigate } from "react-router-dom";

import { ChevronRight } from "@mui/icons-material";

import { useDispatch } from "react-redux";
import { setGameSetupConfig } from "redux/slices/gameSetupSlice";
// import KuvugaBottomNav from "components/footers/kuvuga-footers/KuvugaBottomNav";

import { kuvugaPageView } from "utils/customAnalyticsUtils";

// components
const SectionCards = tw.div`my-2 mx-2 flex flex-col justify-center items-center`;
const Paragraph = tw.p`my-2 font-black text-center text-xl leading-snug max-w-2xl`;

// const SectionsContainer = tw.div` w-[100%] md:w-[60%] relative rounded-lg`;
const Section = tw.button`w-[100%] md:w-[60%] cursor-pointer select-none mt-2 rounded-lg text-gray-800 hover:text-gray-900 bg-gray-300 hover:bg-gray-400 transition duration-300`;
const ActivityContainer = tw.div`pl-4 pr-8 py-3 flex justify-between items-center`;
const SectionTitle = tw.span`pl-2 font-bold capitalize`;
const SectionToggleIcon = motion(styled.span`
  ${tw`ml-2 transition duration-300`}
  svg {
    ${tw`w-6 h-6`}
  }
`);

// main comp
export default () => {
  // custom analytics
  useEffect(() => {
    kuvugaPageView();
  }, []);

  //
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [sectionInfo, setSectionInfo] = useState({});

  useEffect(() => {
    const query = location.search;
    const queryObject = {};

    const searchParams = new URLSearchParams(query);
    for (const [key, value] of searchParams.entries()) {
      queryObject[`${key}`] = `${value}`;
    }
    if (queryObject?.section) {
      queryObject.num = queryObject.section;
    }

    setSectionInfo(queryObject);
  }, [location.search]);

  const sectionActivities = [
    {
      name: "vocabulary",
      path: `${
        sectionInfo.isTable === "false" ? "lesson-learning" : "lesson-table"
      }`,
    },
    { name: "review", path: "lesson-reviewing" },
    // { name: "speaking", path: "listening" },
    { name: "comprehension", path: "lesson-reviewing" },
    { name: "practice", path: "multiple-choice" },
  ];

  useEffect(() => {
    const setPracticeValues = () => {
      dispatch(
        setGameSetupConfig({
          gameTypeState: "multiple-choice",
          gameCategoryState: `${sectionInfo.category}`,
          gameNumQuestionsState: "5",
          gameLevelState: "Beginner",
          gameTimeOptionState: "Time Per Question",
        })
      );
    };
    setPracticeValues();
  });

  return (
    <AnimationRevealPage disabled>
      <Header />
      {/* <Container> */}
      <ContentWithPaddingMd>
        <SectionCards>
          <Paragraph>Section {sectionInfo.num}</Paragraph>
          {sectionActivities.map((activity, index) => (
            <Section
              key={index}
              onClick={() => {
                if (activity.name === "practice") {
                  navigate(`/multiple-choice`);
                } else {
                  navigate(
                    `/${activity.path}?category=${sectionInfo.category}&section=${sectionInfo.num}&isTable=${sectionInfo.isTable}`
                  );
                }
              }}
            >
              <ActivityContainer>
                <SectionTitle>{activity.name}</SectionTitle>
                <SectionToggleIcon>
                  <ChevronRight />
                </SectionToggleIcon>
              </ActivityContainer>
            </Section>
          ))}
        </SectionCards>
      </ContentWithPaddingMd>
      {/* back to top */}
      {/* </Container> */}

      {/* <Footer /> */}
      {/* <KuvugaBottomNav /> */}
    </AnimationRevealPage>
  );
};
