import React, { useEffect, useState } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingMd } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import Header from "components/headers/secondaryLight.js";
// import Footer from "components/footers/kuvuga-footers/KuvugaFiveColumnWithInputForm.js";
import { PrimaryButton } from "components/misc/Buttons";
import { useLocation, useNavigate } from "react-router-dom";
import { Suspense } from "react";

import Skeleton from "@mui/material/Skeleton";
// import Tooltip from "@mui/material/Tooltip";
// import IconButton from "@mui/material/IconButton";
import Fab from "@mui/material/Fab";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import allTopics from "../constants/allTopics.json";
import KuvugaBottomNav from "components/footers/kuvuga-footers/KuvugaBottomNav";

// analytics
import { kuvugaPageView } from "utils/customAnalyticsUtils";

// components
const Categories = tw.div`my-2 md:mx-0 lg:-mr-8 flex flex-wrap justify-center`;
const CategoryContainer = styled.div`
  ${tw`my-2 w-1/2 md:w-1/3 lg:w-1/3 flex justify-center items-center`}
`;
const Category = tw.button`h-48 w-[95%] md:w-56 lg:w-64 cursor-pointer flex flex-col bg-gray-100 rounded-lg`;
const Image = styled.div`
  ${(props) =>
    css`
      background-image: url("${props.imageSrc}");
    `}
  ${tw`h-40 w-full bg-cover bg-no-repeat bg-center rounded-t-lg`}
`;
const Info = tw.div`w-[100%] p-2 border-2 border-t-0 rounded-lg rounded-t-none flex flex-col items-center`;
// const Activity = tw.div`uppercase text-primary-500 text-xs font-bold tracking-widest leading-loose after:content after:block after:border-b-2 after:border-primary-500 after:w-8`;
// const CreationDate = tw.div`mt-4 uppercase text-gray-600 italic font-semibold text-xs`
const Title = tw.div`capitalize mt-1 font-black text-center text-lg lg:text-xl text-gray-900 group-hover:text-primary-500 transition duration-300`;
const TopicCount = tw.p`text-center text-secondary-100 text-xs sm:text-sm font-semibold`;

const ButtonContainer = tw.div`flex justify-center`;
const ScrollTopButtonContainer = tw.div`flex justify-end sticky`;
const LoadMoreButton = tw(PrimaryButton)`mt-8 mx-1`;
// const TopicCount = tw.div`font-semibold mt-2`;

// func component
export default () => {
  // custom analytics
  useEffect(() => {
    kuvugaPageView();
  }, []);

  //
  const navigate = useNavigate();
  const location = useLocation();
  
  const [visible, setVisible] = useState(10);
  const onLoadMoreClick = () => {
    setVisible((v) => v + 6);
  };

  const onViewAllClick = () => {
    setVisible(50);
  };

  const [wordsData, setWordsData] = useState([]);

  useEffect(() => {
    // fetchData();
    try {
      setWordsData(allTopics?.topics);
    } catch (error) {
      console.log("Fetch error: ", error);
    }
  }, []);

  const chosenUrl =
    location.pathname === "/learn-categories"
      ? `/learning?category=`
      : `/reviewing?category=`;

  return (
    <AnimationRevealPage disabled>
      <Header />
      <Container>
        <ContentWithPaddingMd>
          <Categories>
            {/* {Categories.slice(0, visible).map((post, index) => ( */}
            {wordsData &&
              wordsData.slice(0, visible).map((tag, index) => (
                <CategoryContainer key={index} featured={tag.featured}>
                  <Category
                    className="group"
                    // as="a"
                    // href={`${chosenUrl}${wordsData[index].topicTitle}`}
                    // title={`${chosenUrl}${wordsData[index].topicTitle}`}
                    onClick={() =>
                      navigate(`${chosenUrl}${wordsData[index].topicTitle}`)
                    }
                  >
                    <Suspense
                      fallback={
                        <Skeleton
                          variant="rectangular"
                          animation="wave"
                          sx={{
                            width: "100%",
                            height: "25vh",
                            background: "#f3f4f6",
                          }}
                        />
                      }
                    >
                      <Image
                        imageSrc={`assets/images/${wordsData[
                          index
                        ]?.topicTitle.replace(/\s/g, "")}.jpg`}
                        loading="lazy"
                      />
                    </Suspense>
                    <Info>
                      {/* <Activity>{tag.activity}</Activity> */}
                      <Title>{wordsData[index]?.topicTitle}</Title>
                      {/* {tag.featured && tag.description && (
                        <Description>{tag.description}</Description>
                      )} */}
                      {location.pathname === "/learn-categories" ? (
                        <TopicCount>
                          {/* {wordsData[index]?.TopicCount}{" "}
                          {wordsData[index]?.TopicCount > 1
                            ? "Sections"
                            : "Section"} */}
                          {wordsData[index]?.topicCount} Words
                        </TopicCount>
                      ) : null}
                    </Info>
                  </Category>
                </CategoryContainer>
              ))}
          </Categories>
          {visible < wordsData.length ? (
            <ButtonContainer>
              <LoadMoreButton onClick={onLoadMoreClick}>
                Load More
              </LoadMoreButton>
              <LoadMoreButton onClick={onViewAllClick}>View All</LoadMoreButton>
            </ButtonContainer>
          ) : (
            <ScrollTopButtonContainer>
              {/* <Tooltip title="Top" open={true} placement="top"> */}
              <Fab
                sx={{
                  backgroundColor: "#6415FF",
                  color: "#edf2f7",
                  ":hover": {
                    bgcolor: "#6415FF",
                    color: "#edf2f7",
                  },
                }}
                size="small"
                aria-label="scroll back to top"
                onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
              >
                <KeyboardArrowUpIcon color="#edf2f7" />
              </Fab>
              {/* </Tooltip> */}
            </ScrollTopButtonContainer>
          )}
        </ContentWithPaddingMd>
        {/* back to top */}
      </Container>

      {/* <Footer /> */}
      <KuvugaBottomNav />
    </AnimationRevealPage>
  );
};
