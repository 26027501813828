import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { ReactComponent as ChevronDownIcon } from "feather-icons/dist/icons/chevron-down.svg";

import { useNavigate } from "react-router-dom";
import { ChevronRight } from "@mui/icons-material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const LessonsContainer = tw.div`w-[100%] md:w-[60%] relative rounded-lg`;
const Lesson = tw.button`w-[100%] cursor-pointer select-none mt-2 rounded-lg text-gray-800 hover:text-gray-900 bg-gray-300 hover:bg-gray-400 transition duration-300`;
const Question = tw.div`pl-4 pr-8 py-3 flex justify-between items-center`;
const LessonTitle = tw.span`pl-2 font-bold capitalize`;
const LessonToggleIcon = motion(styled.span`
  ${tw`ml-2 transition duration-300`}
  svg {
    ${tw`w-6 h-6`}
  }
`);

const SectionButton = tw.div`w-[100%] cursor-pointer pl-6 pr-8 py-2 border-t-2 border-t-gray-300 text-gray-800 hover:text-gray-900 bg-gray-100 hover:bg-gray-200 flex justify-between items-center`;
const TitleSection = tw.div`flex justify-between items-center`;

export default ({ lessonNumber, numSections, lessonName, isTable }) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [createdSections, setCreatedSections] = useState([]);

  useEffect(() => {
    for (let i = 0; i < numSections; i++) {
      createdSections.push(
        <SectionButton
          key={`${[i]}`}
          onClick={() => {
            navigate(
              `/lesson-section?category=${lessonName}&num=${
                i + 1
              }&isTable=${isTable}`
            );
          }}
          className="group"
        >
          <TitleSection>
            <CheckCircleIcon color="blue" />
            <LessonTitle>Section {i + 1}</LessonTitle>
          </TitleSection>
          <LessonToggleIcon>
            <ChevronRight />
          </LessonToggleIcon>
        </SectionButton>
      );
      setCreatedSections(createdSections);
    }
  }, []); //eslint-disable-line

  return (
    <LessonsContainer>
      <Lesson>
        <Question
          onClick={() => {
            setOpen(!open);
          }}
          className="group"
        >
          <LessonTitle>
            Lesson {lessonNumber}: {lessonName}
          </LessonTitle>
          <LessonToggleIcon
            variants={{
              collapsed: { rotate: 0 },
              open: { rotate: -180 },
            }}
            initial="collapsed"
            animate={open ? "open" : "collapsed"}
            transition={{ duration: 0.02, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            <ChevronDownIcon />
          </LessonToggleIcon>
        </Question>

        {open && createdSections}
      </Lesson>
    </LessonsContainer>
  );
};
