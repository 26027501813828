// from reacgt-a2hs package: https://github.com/kodai3/react-use-a2hs
import { useState, useEffect } from "react";

export const useA2HS = (config) => {
  const [promptEvent, setPromptEvent] = useState(null);

  const promptToInstall = () => {
    if (promptEvent) promptEvent.prompt();
  };

  const isAppInstalled = () => {
    if (
      window.matchMedia("(display-mode: standalone)").matches ||
      window.navigator.standalone
    ) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if(isAppInstalled === true) return;
    const listener = (e) => {
      e.preventDefault();
      setPromptEvent(e);
      e.userChoice
        .then((result) => {
          if (result.outcome === "accepted") {
            if (config?.onAccepted) config.onAccepted();
          } else {
            if (config?.onDismissed) config.onDismissed();
          }
          return;
        })
        .catch(console.error);
    };

    window.addEventListener("beforeinstallprompt", listener);
    return () => {
      window.removeEventListener("beforeinstallprompt", listener);
    };
  }, [config]);

  return [promptEvent, promptToInstall];
};
