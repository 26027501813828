import React, { useState } from "react";

import Paper from "@mui/material/Paper";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import MenuBookRoundedIcon from "@mui/icons-material/MenuBookRounded";
import SchoolRoundedIcon from "@mui/icons-material/SchoolRounded";
import PsychologyRoundedIcon from "@mui/icons-material/PsychologyRounded";
import EmojiEventsRoundedIcon from "@mui/icons-material/EmojiEventsRounded";

import { useLocation, useNavigate } from "react-router-dom";

const KuvugaBottomNav = () => {
  const navigate = useNavigate();
  const location = useLocation();
  
  const [value, setValue] = useState(0);

  return (
    <Paper
      sx={{
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
      }}
      elevation={3}
    >
      <BottomNavigation
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: "#edf2f7",
          "& .MuiBottomNavigationAction-root, .Mui-selected, svg": {
            color: "#6415FF",
          },
          fontWeight: "bold",
        }}
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
      >
        <BottomNavigationAction
          sx={{ color: "#6415FF", ":hover": "#6415FF" }}
          label="Home"
          onClick={() => {
            // navigate("/landing");
            navigate("/home-menu");
          }}
          icon={<HomeRoundedIcon />}
        />

        <BottomNavigationAction
          sx={{ color: "#6415FF", ":hover": "#6415FF" }}
          label="Lessons"
          onClick={() => {
            navigate("/lessons");
          }}
          icon={<MenuBookRoundedIcon />}
        />

        {/* to learning categories */}
        {(location.pathname === "/reviewing" ||
          location.pathname === "/lessons" ||
          location.pathname === "/multiple-choice" ||
          location.pathname === "/fill-blanks" ||
          location.pathname === "/true-false" ||
          location.pathname === "/game-categories" ||
          location.pathname === "/review-categories") && (
          <BottomNavigationAction
            sx={{ color: "#6415FF" }}
            onClick={() => {
              navigate("/learn-categories");
            }}
            label="Learn"
            icon={<SchoolRoundedIcon />}
          />
        )}

        {/* to reviewing categories */}
        {(location.pathname === "/learning" ||
          location.pathname === "/lessons" ||
          location.pathname === "/multiple-choice" ||
          location.pathname === "/fill-blanks" ||
          location.pathname === "/true-false" ||
          location.pathname === "/learn-categories" ||
          location.pathname === "/game-categories") && (
          <BottomNavigationAction
            sx={{ color: "#6415FF" }}
            label="Review"
            onClick={() => {
              navigate("/review-categories");
            }}
            icon={<PsychologyRoundedIcon />}
          />
        )}

        {/* practice */}
        {(location.pathname === "/learning" ||
          location.pathname === "/reviewing" ||
          location.pathname === "/multiple-choice" ||
          location.pathname === "/fill-blanks" ||
          location.pathname === "/true-false" ||
          location.pathname === "/learn-categories" ||
          location.pathname === "/review-categories") && (
          <BottomNavigationAction
            sx={{ color: "#6415FF" }}
            onClick={() => {
              navigate("/game-categories");
            }}
            label="Practice"
            icon={<EmojiEventsRoundedIcon />}
          />
        )}
      </BottomNavigation>
    </Paper>
  );
};

export default KuvugaBottomNav;
