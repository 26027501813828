import React, { useState, useRef } from "react";
import tw from "twin.macro";
import styled from "styled-components";
// import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
// import { ReactComponent as LocationIcon } from "feather-icons/dist/icons/map-pin.svg";
// import { ReactComponent as TimeIcon } from "feather-icons/dist/icons/clock.svg";
import Header from "../../components/headers/secondaryLight";
// import { ReactComponent as LoginIcon } from "feather-icons/dist/icons/log-in.svg";
// import { Button } from "@mui/material";
import KuvugaBottomNav from "components/footers/kuvuga-footers/KuvugaBottomNav";

import { useNavigate } from "react-router-dom";

// import { timeConverter } from "utils";
import allTopicTitles from "../../constants/allTopicTitles";
import allTopics from "../../constants/allTopics";
import { QUESTIONS_TYPE } from "constants";
// eslint-disable-next-line no-unused-vars
import { useSelector, useDispatch } from "react-redux";
import { setGameSetupConfig } from "redux/slices/gameSetupSlice";
// import GameSetupSplitButton from "components/misc/GameSetupSplitButton";
import GameSetupDropdown from "components/misc/GameSetupDropdown";

//
const Layout = tw.div`bg-gray-100 font-display min-h-screen text-secondary-500 p-8 overflow-hidden`;
const Container = styled.div`
  ${tw`h-[80vh] w-full overflow-hidden flex flex-col items-center`}
`;

const Card = styled.div`
  ${tw`grow w-[75%] flex flex-col justify-center`}
`;
// const CardText = tw.div`h-5/6 w-full flex flex-col justify-between capitalize`;
// const CardTitle = tw.h1`h-2/3 w-full text-4xl font-bold flex justify-center items-center bg-gray-200`;
// const CardTranslation = tw.h5`h-1/3 text-2xl mt-1 font-bold flex justify-center items-center bg-gray-100 capitalize`;
// const CardAction = tw(PrimaryButtonBase)`w-full`;
// const CounterSpan = tw.span`font-bold`;
// const Input = tw.input`w-full sm:w-auto block sm:inline-block px-6 py-4 rounded bg-secondary-600 tracking-wider font-bold border border-secondary-600 focus:border-secondary-300 focus:outline-none sm:rounded-r-none hover:bg-secondary-500 transition duration-300 text-gray-200`
// const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const SetupSection = tw.div`mx-auto max-w-xs w-full`;
// const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-primary-500 text-gray-100 w-full py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;
// const ErrorSection = tw.p`mt-2 text-red-600 font-bold text-center`;
// const TimeContainer = tw.div`w-[100%] mt-2 flex flex-row justify-between space-x-1`;

export default ({ submitButtonText = "Begin" }) => {
  // const storeSetupValues = useSelector((state) => state?.gameSetupConfig);
  // console.log("from store: ", storeSetupValues);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // eslint-disable-next-line no-unused-vars
  const [gameCategoryControl, setGameCategoryControl] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [gameNumQuestionsControl, setGameNumQuestionsControl] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [gameDifficultyControl, setGameDifficultyControl] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [gameTimeOptionControl, setGameTimeOptionControl] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [gameHourControl, setGameHourControl] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [gameMinControl, setGameMinControl] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [gameSecControl, setGameSecControl] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [gameType, setGameType] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [gameCategory, setGameCategory] = useState("");
  const [gameNumQuestions, setGameNumQuestions] = useState("");
  // const [gameLevel, setGameLevel] = useState("");
  // const [gameTimeOption, setGameTimeOption] = useState("");
  // const [gameHour, setGameHour] = useState("");
  // const [gameMin, setGameMin] = useState("");
  // const [gameSec, setGameSec] = useState("");

  // eslint-disable-next-line no-unused-vars
  const [errorState, setErrorState] = useState(false);

  const gameTypesArr = [
    "Multiple Choice",
    "Fill in the Blanks",
    "True / False",
  ];
  const gameNumQuestionsArr = [5, 10, 15, 20, 25, 30];
  // const gameLevelsArr = ["Beginner", "Novice", "Intermediate", "Advanced"];
  // const gameTimeOptionArr = ["Time Per Question", "Time Per Practice"];

  //
  const handleTypeChange = (selection) => {
    setGameType(selection);
    setGameCategoryControl(false);
    dispatch(setGameSetupConfig({ gameTypeState: selection }));
  };

  const getCategoryObj = allTopics?.topics?.filter((topic) => Number(topic.topicCount) >= Number(gameNumQuestions))
  const getCategoryArr = getCategoryObj.map((topic) => topic.topicTitle)

  const getCategoryRef = useRef(
    Math.floor(Math.random() * allTopicTitles.titles.length)
  );
  const handleCategoryChange = (selection) => {
    if (selection === "Any") {
      setGameCategory(allTopicTitles.titles[getCategoryRef.current]);
      dispatch(
        setGameSetupConfig({
          gameCategoryState: `${allTopicTitles.titles[getCategoryRef.current]}`,
        })
      );
    } else {
      setGameCategory(selection);
      setGameNumQuestionsControl(false);
      dispatch(setGameSetupConfig({ gameCategoryState: selection }));
    }
  };

  const handleNumChange = (selection) => {
    setGameNumQuestions(selection);
    setGameDifficultyControl(false);
    dispatch(setGameSetupConfig({ gameNumQuestionsState: selection }));
  };

  // const handleDifficultyChange = (selection) => {
  //   setGameLevel(selection);
  //   if (gameLevel === "Novice") {
  //     setGameTimeOption("Time Per Question");
  //     setGameHourControl(true);
  //     setGameMinControl(true);
  //   }
  //   setGameTimeOptionControl(false);
  //   dispatch(setGameSetupConfig({ gameLevelState: selection }));
  // };

  // const handleTimeOptionChange = (selection) => {
  //   // setGameSecControl(false);
  //   setGameTimeOption(selection);
  //   console.log("time opt: ", gameTimeOption);
  //   dispatch(setGameSetupConfig({ gameTimeOptionState: selection }));

  //   if (selection === "Time Per Question") {
  //     if (gameLevel === "Beginner") {
  //       setGameSec("30");
  //     } else if (gameLevel === "Novice") {
  //       setGameSec("20");
  //     } else if (gameLevel === "Intermediate") {
  //       setGameSec("10");
  //     } else if (gameLevel === "Advanced") {
  //       setGameSec("05");
  //     }
  //   }
  //   if (selection === "Time Per Practice") {
  //     if (gameLevel === "Beginner") {
  //       const totalTime = Number(gameNumQuestions) * 30 * 1000;
  //       console.log("tot: ", timeConverter(totalTime));
  //       timeConverter(totalTime);
  //       setGameHour(timeConverter(totalTime).hours);
  //       setGameMin(timeConverter(totalTime).minutes);
  //       setGameSec(timeConverter(totalTime).seconds);
  //     } else if (gameLevel === "Novice") {
  //       const totalTime = Number(gameNumQuestions) * 20 * 1000;
  //       console.log("tot: ", timeConverter(totalTime));
  //       timeConverter(totalTime);
  //       setGameHour(timeConverter(totalTime).hours);
  //       setGameMin(timeConverter(totalTime).minutes);
  //       setGameSec(timeConverter(totalTime).seconds);
  //     } else if (gameLevel === "Intermediate") {
  //       const totalTime = Number(gameNumQuestions) * 10 * 1000;
  //       console.log("tot: ", timeConverter(totalTime));
  //       timeConverter(totalTime);
  //       setGameHour(timeConverter(totalTime).hours);
  //       setGameMin(timeConverter(totalTime).minutes);
  //       setGameSec(timeConverter(totalTime).seconds);
  //     } else if (gameLevel === "Advanced") {
  //       const totalTime = Number(gameNumQuestions) * 5 * 1000;
  //       console.log("tot: ", timeConverter(totalTime));
  //       timeConverter(totalTime);
  //       setGameHour(timeConverter(totalTime).hours);
  //       setGameMin(timeConverter(totalTime).minutes);
  //       setGameSec(timeConverter(totalTime).seconds);
  //     }
  //   }
  // };

  const handleFormFilled = () => {
    const gameTypeObj = QUESTIONS_TYPE.find(
      (qstType) => qstType?.title === gameType
    );
    if (
      gameType === "" ||
      gameCategory === "" ||
      gameNumQuestions === "" 
      //  || gameLevel === "" ||
      // gameTimeOption === ""
    ) {
      setErrorState(true);
      return;
    }
    navigate(`${gameTypeObj?.url}`);
  };

  // const handleHourChange = (event) => {
  //   console.log(selection);
  //   setGameHour(selection);
  //   dispatch(
  //     setGameSetupConfig({
  //       gameTimeState: {
  //         hrState: selection,
  //         minState: `${gameMin}`,
  //         secState: `${gameSec}`,
  //       },
  //     })
  //   );
  // };

  // const handleMinChange = (event) => {
  //   // console.log(selection);
  //   setGameMin(selection);
  //   dispatch(
  //     setGameSetupConfig({
  //       gameTimeState: {
  //         hrState: `${gameHour}`,
  //         minState: selection,
  //         secState: `${gameSec}`,
  //       },
  //     })
  //   );
  // };

  // const handleSecChange = (event) => {
  //   // console.log(selection);
  //   setGameSec(selection);
  //   dispatch(
  //     setGameSetupConfig({
  //       gameTimeState: {
  //         hrState: `${gameHour}`,
  //         minState: `${gameMin}`,
  //         secState: selection,
  //       },
  //     })
  //   );
  // };

  return (
    <Layout>
      <Container>
        <Header />
        <Card>
          {/* <FormContainer> */}
          <SetupSection>
            {/* Game Type */}
            <GameSetupDropdown
              dropDownStyles={{
                display: "flex",
                justifyContent: "center",
              }}
              hasError={errorState}
              placeholderText={"Game Type"}
              compArr={gameTypesArr}
              compHandler={handleTypeChange}
            />

            {/* Number of Questions */}
            <GameSetupDropdown
              dropDownStyles={{
                display: "flex",
                justifyContent: "center",
              }}
              hasError={errorState}
              placeholderText={"Number of Questions"}
              compArr={gameNumQuestionsArr}
              compHandler={handleNumChange}
            />

            {/* Category */}
            <GameSetupDropdown
              dropDownStyles={{
                display: "flex",
                justifyContent: "center",
              }}
              hasError={errorState}
              placeholderText={"Select a Category"}
              // compArr={allTopicTitles?.titles}
              compArr={getCategoryArr}
              compHandler={handleCategoryChange}
            />
            {/* <GameSetupSplitButton
              disabledState={gameCategoryControl}
              selectLabel={"Category"}
              selectHandler={handleCategoryChange}
              selectArray={allTopicTitles?.titles}
              addMenu={true}
              addMenuLabel={"Any"}
            /> */}

            {/* Difficulty */}
            {/* <GameSetupDropdown
              dropDownStyles={{
                display: "flex",
                justifyContent: "center",
              }}
              hasError={errorState}
              placeholderText={"Difficulty"}
              compArr={gameLevelsArr}
              compHandler={handleDifficultyChange}
            /> */}

            {/* Time Option */}
            {/* <GameSetupDropdown
              dropDownStyles={{
                display: "flex",
                justifyContent: "center",
              }}
              hasError={errorState}
              placeholderText={"Time Option"}
              compArr={gameTimeOptionArr}
              compHandler={handleTimeOptionChange}
            /> */}

            {/* time option */}

            {/* time - duration */}
            {/* <TimeContainer>
              <Button
                variant="outlined"
                size="large"
                disableRipple
                disableTouchRipple
                disableFocusRipple
                sx={{
                  textTransform: "capitalize",
                  marginLeft: "0px",
                  backgroundColor: "#6415FF",
                  fontWeight: "bold",
                  marginBottom: "5px",
                  color: "#f3f4f6",
                  ":hover": {
                    bgcolor: "#6415FF",
                    color: "#edf2f7",
                  },
                }}
              >
                {gameHour ? `${gameHour} Hr` : "Hr"}
              </Button>
              <Button
                variant="outlined"
                size="large"
                disableRipple
                disableTouchRipple
                disableFocusRipple
                sx={{
                  textTransform: "capitalize",
                  backgroundColor: "#6415FF",
                  fontWeight: "bold",
                  marginBottom: "5px",
                  color: "#f3f4f6",
                  ":hover": {
                    bgcolor: "#6415FF",
                    color: "#edf2f7",
                  },
                }}
              >
                {gameMin ? `${gameMin} Min` : "Min"}
              </Button>
              <Button
                variant="outlined"
                size="large"
                disableRipple
                disableTouchRipple
                disableFocusRipple
                sx={{
                  textTransform: "capitalize",
                  backgroundColor: "#6415FF",
                  fontWeight: "bold",
                  marginBottom: "5px",
                  color: "#f3f4f6",
                  ":hover": {
                    bgcolor: "#6415FF",
                    color: "#edf2f7",
                  },
                }}
              >
                {gameSec ? `${gameSec} Sec` : "Sec"}
              </Button>
            </TimeContainer> */}
            
            <SubmitButton
              // type="submit"
              onClick={() => {
                handleFormFilled();
              }}
            >
              {/* <SubmitButtonIcon className="icon" /> */}
              <span className="text">{submitButtonText}</span>
            </SubmitButton>

          </SetupSection>
        </Card>
      </Container>
      <KuvugaBottomNav />
    </Layout>
  );
};
