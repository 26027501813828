import React, { useState } from "react";
import axios from "axios";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container as ContainerBase } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
// import illustration from "images/login-illustration.svg";
import illustration from "images/undraw_connected_world_wuay.svg";
// import logo from "images/logo.svg";
// import googleIconImageSrc from "images/google-icon.png";
// import twitterIconImageSrc from "images/twitter-icon.png";
// import { ReactComponent as FacebookIcon } from "images/facebook-icon.svg";
import { ReactComponent as LoginIcon } from "feather-icons/dist/icons/log-in.svg";
import { useNavigate } from "react-router-dom"; // redux
import { useDispatch } from "react-redux";
import { updateLanguageSetupConfig } from "redux/slices/languageSetupSlice";

// mui
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
import { devValueLogger } from "utils/devLogger";

// fn comp
const Container = tw(
  ContainerBase
)`min-h-screen bg-primary-900 text-white font-medium flex justify-center -m-8`;
const Content = tw.div`max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`lg:w-1/2 xl:w-5/12 p-6 sm:p-12`;
// const LogoLink = tw.a``;
// const LogoImage = tw.img`h-12 mx-auto`;
const MainContent = tw.div`mt-12 flex flex-col items-center`;
const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold`;
const FormContainer = tw.div`w-full flex-1 mt-8`;

// const SocialButtonsContainer = tw.div`flex flex-col items-center`;

// const SocialButton = styled.a`
//   ${tw`w-full max-w-xs font-semibold rounded-lg py-3 border text-gray-900 bg-gray-100 hocus:bg-gray-200 hocus:border-gray-400 flex items-center justify-center transition-all duration-300 focus:outline-none focus:shadow-outline text-sm mt-5 first:mt-0`}
//   .iconContainer {
//     ${tw`bg-white p-2 rounded-full`}
//   }
//   .icon {
//     ${tw`w-4`}
//   }
//   .text {
//     ${tw`ml-4`}
//   }
// `;

// const DividerTextContainer = tw.div`my-12 border-b text-center relative`;
// const DividerText = tw.div`leading-none px-2 inline-block text-sm text-gray-600 tracking-wide font-medium bg-white transform -translate-y-1/2 absolute inset-x-0 top-1/2 bg-transparent`;

const Form = tw.form`mx-auto max-w-xs`;
const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-primary-500 text-gray-100 w-full py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;
const IllustrationContainer = tw.div`sm:rounded-r-lg flex-1 bg-purple-100 text-center hidden lg:flex justify-center`;
const IllustrationImage = styled.div`
  ${(props) => `background-image: url("${props.imageSrc}");`}
  ${tw`m-12 xl:m-16 w-full max-w-sm bg-contain bg-center bg-no-repeat`}
`;

export default ({
  logoLinkUrl = "/login",
  illustrationImageSrc = illustration,
  headingText = "KUVUGA",
  // socialButtons = [
  //   {
  //     iconImageSrc: googleIconImageSrc,
  //     text: "Sign In With Google",
  //     url: "https://google.com"
  //   },
  //   {
  //     iconImageSrc: twitterIconImageSrc,
  //     text: "Sign In With Twitter",
  //     url: "https://twitter.com"
  //   },
  //   {
  //     iconImageSrc: FacebookIcon,
  //     text: "Sign In With Facebook",
  //     url: "https://facebook.com"
  //   }
  // ],
  submitButtonText = "Sign In",
  SubmitButtonIcon = LoginIcon,
  // forgotPasswordUrl = "#",
  registerUrl = "/register",
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const [passcode, setPasscode] = useState("");
  // const [wrongPasscode, setWrongPasscode] = useState("");
  const [showPassword, setShowpassword] = useState(false);
  const [isGuest, setIsGuest] = useState(false);
  const [loading, setLoading] = useState(false);

  const [inputUsername, setInputUsername] = useState("");
  const [inputPassword, setInputPassword] = useState("");

  devValueLogger("guest? ", isGuest);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    // try {
    //   if (!passcode || passcode !== process.env.REACT_APP_PASSCODE) {
    //     setWrongPasscode("Wrong Passcode");
    //     setPasscode("");
    //     return;
    //   }
    //   if (passcode === process.env.REACT_APP_PASSCODE) {
    //     navigate("/landing");
    //   }
    //   setPasscode("");
    // } catch (error) {
    //   console.log(error);
    // }

    // check for compliant identifier
    const _usdevidvalue = generateUniqueIdentifier();

    if (
      !localStorage.getItem("_usdevid") ||
      localStorage.getItem("_userdevid") === ""
    ) {
      localStorage.setItem("_usdevid", _usdevidvalue);
    }

    // check for language settings
    if (
      !localStorage.getItem("learningLanguage") ||
      localStorage.getItem("learningLanguage") === ""
    ) {
      navigate("/language-list");
    } else {
      dispatch(
        updateLanguageSetupConfig({
          knownLanguageState: localStorage.getItem("knownLanguage"),
          learningLanguageState: localStorage.getItem("learningLanguage"),
        })
      );
    }

    // login flow
    // const { email, password } = loginValues;
    // const loginUser = { email, password };
    const userCreds = !isGuest
      ? { username: "un", password: "pw" }
      : { username: inputUsername, password: inputPassword };
    try {
      // const { data } = await axios.post(`/api/v1/auth`, loginUser);
      await axios
        .post(
          `${
            process.env.NODE_ENV?.toLowerCase().trim() === "production"
              ? `${process.env.REACT_APP_SERVER_PROD_URL}/api/v1/auth&apiKey=${process.env.REACT_APP_FREE_TRIAL_API_KEY}`
              : `${process.env.REACT_APP_SERVER_DEV_URL}/api/v1/auth/login`
          }`,
          userCreds,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(function (response) {
          devValueLogger("auth login: ", response);
        })
        .catch(function (error) {
          devValueLogger("auth error: ", error);
        });
      setInputUsername("");
      setInputPassword("");
      setLoading(false);

      // navigate("/home-menu");
    } catch (error) {
      setLoading(false);
    }
  };

  const showPasswordFn = () => {
    try {
      setShowpassword(!showPassword);
    } catch (error) {
      devValueLogger("fetch function error: ", error);
    }
  };

  // Hashing function (example: simple string to number hashing)
  const hashCode = (str) => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      const char = str.charCodeAt(i);
      hash = (hash << 5) - hash + char;
    }
    return hash;
  };

  // Function to generate a unique identifier based on device/browser characteristics
  const generateUniqueIdentifier = () => {
    // Combine relevant characteristics (example: navigator information, screen resolution)
    const combinedInfo = `${navigator.userAgent}${window.screen.width}${window.screen.height}${window.screen.colorDepth}`;

    // Hash the combined information to create a unique identifier
    const hashedIdentifier = hashCode(combinedInfo);

    // return hashedIdentifier;
    if (hashedIdentifier.length) devValueLogger("id: ", hashedIdentifier);
  };

  return (
    <AnimationRevealPage disabled>
      <Container>
        <Content>
          <MainContainer>
            {/* <LogoLink href={logoLinkUrl}>
              <LogoImage src={logo} />
            </LogoLink> */}
            <MainContent>
              <Heading>{headingText}</Heading>
              <FormContainer>
                {/* <SocialButtonsContainer>
                {socialButtons.map((socialButton, index) => (
                  <SocialButton key={index} href={socialButton.url} onClick={() => {}}>
                    <span className="iconContainer">
                      <img src={socialButton.iconImageSrc} className="icon" alt=""/>
                    </span>
                    <span className="text">{socialButton.text}</span>
                  </SocialButton>
                ))}
              </SocialButtonsContainer>
              <DividerTextContainer>
                <DividerText>Or Sign in with your e-mail</DividerText>
              </DividerTextContainer> */}
                <Form onSubmit={handleSubmit}>
                  {/* <Input type="email" placeholder="Email" /> */}
                  <Input
                    type="text"
                    placeholder="Username"
                    value={inputUsername}
                    onChange={(e) => {
                      setInputUsername(e.target.value);
                      // setWrongPasscode("");
                    }}
                  />
                  <Input
                    type={showPassword ? "password" : "text"}
                    placeholder="Password"
                    value={inputPassword}
                    onChange={(e) => {
                      setInputPassword(e.target.value);
                      // setWrongPasscode("");
                    }}
                  />
                  <FormControlLabel
                    required
                    sx={{
                      fontWeight: "bold",
                    }}
                    control={
                      <Checkbox
                        sx={{
                          // color: "#6415FF",
                          "&.Mui-checked": {
                            color: "#6415FF",
                          },
                        }}
                      />
                    }
                    label="Show Password"
                    onClick={showPasswordFn}
                  />
                  {/* <SubmitButton type="submit" onClick={(e) => {if(e.target.value === '1959') Navigate('/KuvugaLandingPage')}}> */}
                  <SubmitButton
                    // type="submit"
                    type="button"
                    onClick={handleSubmit}
                  >
                    <SubmitButtonIcon className="icon" disabled={loading} />
                    <span className="text">
                      {loading ? "Loading..." : submitButtonText}
                    </span>
                  </SubmitButton>

                  <Divider sx={{ mt: 2, fontWeight: "bold" }}>
                    <Chip label="OR" sx={{ background: "#f7fafc" }} />
                  </Divider>
                  <SubmitButton
                    // type="submit"
                    type="button"
                    disabled={loading}
                    onClick={(e) => {
                      setIsGuest(true);
                      handleSubmit(e);
                    }}
                  >
                    <span className="text">
                      {loading ? "Loading..." : "Continue as Guest"}
                    </span>
                  </SubmitButton>
                </Form>
                {/* <p tw=" mt-6 text-xs text-red-600 text-center">
                  {wrongPasscode}
                </p> */}

                {/* forgot password */}
                {/* <p tw="mt-6 text-xs text-gray-600 text-center">
                <a href={forgotPasswordUrl} tw="border-b border-gray-500 border-dotted">
                  Forgot Passcode ?
                </a>
              </p> *}

              {/* register */}
                <p tw="mt-8 text-sm text-gray-600 text-center">
                  Dont have an account?{" "}
                  <a
                    href={registerUrl}
                    tw="border-b border-gray-500 border-dotted"
                  >
                    Sign Up
                  </a>
                </p>
              </FormContainer>
            </MainContent>
          </MainContainer>
          <IllustrationContainer>
            <IllustrationImage imageSrc={illustrationImageSrc} />
          </IllustrationContainer>
        </Content>
      </Container>
    </AnimationRevealPage>
  );
};
