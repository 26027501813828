import React, { useState, useEffect, useRef } from "react";
import tw from "twin.macro";
import styled from "styled-components";
// import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
// import Header from "../components/headers/secondaryLight";
// import { Container as ContainerBase } from "components/misc/Layouts.js";
import AnimationRevealPage from "helpers/AnimationRevealPage";
import { useNavigate } from "react-router-dom";
import EndPlayingCard from "components/cards/kuvuga-cards/EndPlayingCard";
import { shuffle } from "utils";
import Loading from "components/progress/Loading";
import allTopicTitles from "../../constants/allTopicTitles";
import { Button } from "@mui/material";
import KuvugaBottomNav from "components/footers/kuvuga-footers/KuvugaBottomNav";
import { QUESTIONS_TYPE } from "constants";

import { useSelector } from "react-redux";
import { devMsgLogger } from "utils/devLogger";

const Container = styled.div`
  ${tw`h-[89vh] w-full overflow-hidden flex flex-col justify-between`}
`;
const Content = styled.div`
  ${tw`h-2/3 w-full flex justify-center`}
`;

const Card = styled.div`
  ${tw`w-full lg:w-1/2`}
`;
const CardText = tw.div`h-5/6 w-full flex flex-col justify-between capitalize`;
const QuestionTitle = tw.h5`h-1/3 text-4xl mt-1 font-bold flex justify-center items-center bg-gray-200 capitalize rounded-t-md`;
const QuestionDescription = tw.h5`h-[15px] text-base mt-1 font-bold flex justify-center items-center capitalize`;
const CardWord = tw.div`h-1/3 w-full text-3xl font-bold flex justify-center items-center text-center bg-gray-100`;
// const CardTranslation = tw.h5`h-1/3 text-2xl mt-1 font-bold flex justify-center items-center bg-gray-100 capitalize`;
// const CardAction = tw(PrimaryButtonBase)`w-full text-2xl capitalize mt-2`;

// const FooterContainer = tw(
// ContainerBase
// )`w-full lg:w-1/2 mx-auto rounded bg-gray-900 text-gray-100`;
const ButtonsContainer = tw.div` mb-8 mt-5 flex justify-around items-center`;
// const DirectionButton = tw(PrimaryButtonBase)``;
const CounterSpan = tw.span`font-bold`;

export default () => {
  const storeSetupValues = useSelector((state) => state?.gameSetupConfig);
  // console.log("from store: ", storeSetupValues);

  const numQuestionsRef = useRef(storeSetupValues.gameNumQuestionsState);
  const setNumQuestions =
    numQuestionsRef.current !== "" ||
    numQuestionsRef.current !== undefined ||
    numQuestionsRef.current !== null
      ? numQuestionsRef.current
      : 5;
  const navigate = useNavigate();

  const [wordsData, setWordsData] = useState([]);
  // eslint-disable-next-line
  const [flipped, setFlipped] = useState(false);
  const [index, setIndex] = useState(0);
  const [isCompleted, setIsCompleted] = useState(false);
  const [correctAnswers, setCorrectAnswers] = useState(0);
  const [categories, setCategories] = useState([]);
  const [randomQuestions, setRandomQuestions] = useState([]);
  const [randomAnswers, setRandomAnswers] = useState([]);
  const [isRepeating, setIsRepeating] = useState(false);

  useEffect(() => {
    setCategories(allTopicTitles?.titles);
  }, [categories]);

  const getCategoryRef = useRef(
    Math.floor(Math.random() * allTopicTitles.titles.length)
  );
  const storeCategoryRef = useRef(storeSetupValues.gameCategoryState);

  const selectedCategory =
    storeCategoryRef.current !== "" ||
    storeCategoryRef.current !== "Any" ||
    storeCategoryRef.current !== undefined ||
    storeCategoryRef.current !== null
      ? storeCategoryRef.current
      : categories[getCategoryRef.current];

  useEffect(() => {
    if (
      getCategoryRef.current !== undefined &&
      getCategoryRef.current !== null &&
      categories[getCategoryRef.current] !== undefined
    ) {
      async function fetchData() {
        try {
          await fetch(
            process.env.NODE_ENV?.toLowerCase().trim() === "production"
              ? `${process.env.REACT_APP_SERVER_PROD_URL}/api/v1/words?tag=${selectedCategory}&apiKey=${process.env.REACT_APP_FREE_TRIAL_API_KEY}`
              : `${process.env.REACT_APP_SERVER_DEV_URL}/api/v1/words?tag=${selectedCategory}&apiKey=${process.env.REACT_APP_FREE_TRIAL_API_KEY}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Accept: "applicaton/json",
              },
            }
          )
            .then((response) => response.json())
            .then((data) => {
              setWordsData(data.words);
            });
        } catch (error) {
          process.env.NODE_ENV?.toLowerCase().trim() === "production"
            ? devMsgLogger("Something went wrong. Please try again!")
            : devMsgLogger("fetch function error: ", error);
        }
      }
      // call fetch
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories]);

  const startRef = useRef(Math.floor(Math.random() * wordsData?.length));

  useEffect(() => {
    // const start = Math.floor(Math.random() * wordsData?.length);
    const end = startRef.current + setNumQuestions;
    const slicedWords = wordsData?.slice(startRef.current, end);
    setRandomQuestions(slicedWords);

    const randAnswers = shuffle(slicedWords);
    setRandomAnswers(randAnswers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wordsData]); // adding randomQuestions causes an infinite loop
  // console.log("RAND WORDS: ", randomQuestions);

  // handlers
  const checkNumber = (number) => {
    if (number > randomQuestions.length - 1) {
      // return 0;
      setIsCompleted(true);
    }
    if (number < 0) {
      // return findWords.length - 1;
      return 0;
    }
    return number;
  };

  // const handleFlipCard = () => {
  //   flipped ? setFlipped(false) : setFlipped(true);
  // };

  const handleSelectedTrue = () => {
    if (
      randomAnswers[index]?.kiny_word_sing ===
        randomQuestions[index]?.kiny_word_sing ||
      randomAnswers[index]?.kiny_word_sing ===
        randomQuestions[index]?.kiny_word_sing
    ) {
      setCorrectAnswers(correctAnswers + 1);
    }
    // console.log("SCORE: ", correctAnswers);

    setIndex((index) => {
      let newIndex = index + 1;
      return checkNumber(newIndex);
    });
  };

  const handleSelcetedFalse = () => {
    if (
      randomAnswers[index]?.kiny_word_sing !==
        randomQuestions[index]?.kiny_word_sing ||
      randomAnswers[index]?.kiny_word_sing !==
        randomQuestions[index]?.kiny_word_sing
    ) {
      setCorrectAnswers(correctAnswers + 1);
    }
    // console.log("SCORE: ", correctAnswers);

    setIndex((index) => {
      let newIndex = index + 1;
      return checkNumber(newIndex);
    });
  };

  const handleRepeat = () => {
    const gameTypeObj = QUESTIONS_TYPE.find(
      (qstType) => qstType?.title === storeSetupValues.gameTypeState
    );
    setIsRepeating(true);
    setCorrectAnswers(0);
    navigate(`${gameTypeObj?.url}`);
    setIsCompleted(false);
    setIndex(0);
  };
  // console.log("SCORE AFTER: ", correctAnswers);

  return (
    <AnimationRevealPage disabled>
      <Container>
        {/* <Header /> */}
        {isCompleted && (
          <EndPlayingCard
            handleRepeat={handleRepeat}
            score={isRepeating === "true" ? 0 : correctAnswers}
            numQuestions={randomQuestions.length}
          />
        )}
        {!isCompleted && wordsData.length !== 0 && (
          <>
            <Content>
              <Card>
                <CardText>
                  {wordsData.length === 0 && (
                    <Loading colorValue={"#6415FF"} variant={"inherit"} />
                  )}
                  {/* <QuestionDescription>
                    The {flipped ? "Kinyarwanda" : "English"} word:{" "}
                  </QuestionDescription> */}
                  <QuestionTitle>
                    {flipped
                      ? randomQuestions[index]?.kiny_word_sing
                      : randomQuestions[index]?.eng_word_sing}
                  </QuestionTitle>
                  <QuestionDescription>translates to</QuestionDescription>
                  <CardWord>
                    {flipped
                      ? randomAnswers[index]?.eng_word_sing
                      : randomAnswers[index]?.kiny_word_sing}
                  </CardWord>
                  {/* <CardTranslation>
                    {flipped
                      ? findWords[index].kinyarwanda
                      : findWords[index].english}
                  </CardTranslation> */}
                  <Button
                    variant="contained"
                    // size={`${window.innerHeight >= 800 ? "large" : "medium"}`}
                    size="medium"
                    disableRipple
                    disableTouchRipple
                    disableFocusRipple
                    sx={{
                      backgroundColor: "#6415FF",
                      marginBottom: "5px",
                      fontSize: "1rem",
                      fontWeight: "bold",
                      color: "#f3f4f6",
                      ":hover": {
                        bgcolor: "#5011cc",
                        color: "#edf2f7",
                      },
                    }}
                    onClick={handleSelectedTrue}
                  >
                    TRUE
                  </Button>
                  <Button
                    variant="contained"
                    // size={`${window.innerHeight >= 800 ? "large" : "medium"}`}
                    size="medium"
                    disableRipple
                    disableTouchRipple
                    disableFocusRipple
                    sx={{
                      backgroundColor: "#6415FF",
                      marginBottom: "5px",
                      fontSize: "1rem",
                      fontWeight: "bold",
                      color: "#f3f4f6",
                      ":hover": {
                        bgcolor: "#5011cc",
                        color: "#edf2f7",
                      },
                    }}
                    onClick={handleSelcetedFalse}
                  >
                    FALSE
                  </Button>
                </CardText>
              </Card>
            </Content>
            {/* <FooterContainer> */}
            <ButtonsContainer>
              {/* <DirectionButton onClick={handlePreviousCard}>
                  BACK
                </DirectionButton> */}
              <CounterSpan>
                Question {index + 1} / {randomQuestions?.length}
              </CounterSpan>
              {/* <DirectionButton onClick={handleNextCard}>NEXT</DirectionButton> */}
            </ButtonsContainer>
            {/* </FooterContainer> */}
          </>
        )}
        <KuvugaBottomNav />
      </Container>
    </AnimationRevealPage>
  );
};
