import React, { useEffect } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/secondaryLight.js";
import Footer from "components/footers/kuvuga-footers/KuvugaFiveColumnWithInputForm";
import MainFeature1 from "components/features/kuvuga-features/KuvugaTwoColWithButton.js";
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature3 from "components/features/TwoColSingleFeatureWithStats2.js";
import Features from "components/features/ThreeColSimple.js";
// import Features from "components/features/ThreeColWithSideImage.js";
// import TeamCardGrid from "components/cards/ProfileThreeColGrid.js";

// import SupportIconImage from "images/support-icon.svg";
// import ShieldIconImage from "images/shield-icon.svg";
// import CustomerLoveIconImage from "images/simple-icon.svg";
import LearningIconImage from "images/e-learning.svg";
import GlobeIconImage from "images/earth-africa-bold.svg";
import RocketIconImage from "images/rocket-launch.svg";

// analytics
import { kuvugaPageView } from "utils/customAnalyticsUtils";

const Subheading = tw.span`uppercase tracking-wider text-sm`;

export default () => {
  // custom analytics
  useEffect(() => {
    kuvugaPageView();
  }, []);

  return (
    <AnimationRevealPage>
      <Header />
      <MainFeature1
        subheading={<Subheading>About Kuvuga</Subheading>}
        heading="We are a modern learning platform."
        description={
          "Kuvuga is a cutting-edge language learning platform designed to revolutionize the way you acquire new languages. With a sleek and intuitive interface, LinguaLink combines state-of-the-art technology with multiple learning paths to cater to individual needs and preferences."
        }
        buttonRounded={false}
        primaryButtonText="Get Started"
        primaryButtonUrl="/home-menu"
        imageSrc="https://images.unsplash.com/photo-1519389950473-47ba0277781c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=768&q=80"
      />
      <MainFeature1
        subheading={<Subheading>Our Vision</Subheading>}
        heading="We aim to disrupt the language learning space."
        description={
          "Our vision is to break down language barriers and foster a globally connected community where communication knows no bounds. We aspire to empower individuals from diverse backgrounds to unlock the full potential of their linguistic abilities, promoting understanding and cultural appreciation. Kuvuga envisions a future where language learning is not only effective but also enjoyable, where learners can seamlessly navigate the rich tapestry of global languages. By harnessing the power of technology and innovation, we aim to create a platform that not only imparts linguistic proficiency but also cultivates a sense of curiosity, openness, and collaboration. Kuvuga's vision extends beyond words—it's about building bridges between people, transcending borders, and fostering a world where everyone can express themselves confidently in any language they choose."
        }
        buttonRounded={false}
        primaryButtonText="Contact Us"
        primaryButtonUrl="/contact"
        imageSrc="https://images.unsplash.com/3/doctype-hi-res.jpg?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80"
        textOnLeft={false}
      />
      <Features
        subheading={<Subheading>Our Values</Subheading>}
        heading="We follow these."
        description="Kuvuga is driven by the core value of empowering individuals through education."
        cards={[
          {
            imageSrc: LearningIconImage,
            title: "Empowerment through Education",
            description:
              "We believe that knowledge is a catalyst for personal and societal growth. By providing accessible and effective language learning tools, we empower learners to confidently navigate a multilingual world, expanding their opportunities and perspectives.",
          },
          {
            imageSrc: GlobeIconImage,
            title: "Inclusivity and Cultural Appreciation",
            description:
              "At Kuvuga, we embrace the richness of global diversity. Our commitment to inclusivity and cultural appreciation is woven into the fabric of our platform. We celebrate linguistic differences as a means of fostering mutual understanding and respect. By promoting cultural awareness, we contribute to a world where people connect on a deeper level, transcending language barriers.",
          },
          {
            imageSrc: RocketIconImage,
            title: "Innovation for Seamless Learning",
            description:
              "Kuvuga is committed to continuous innovation in language learning. We value the integration of cutting-edge technology to create a seamless and enjoyable learning experience. Through adaptive learning paths, interactive tools, and real-time feedback, we strive to make language acquisition not only efficient but also a source of inspiration and joy for our users.",
          },
        ]}
        linkText=""
      />
      {/* <TeamCardGrid subheading={<Subheading>Our Team</Subheading>} /> */}
      <Footer />
    </AnimationRevealPage>
  );
};
