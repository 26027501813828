import { devMsgLogger } from "./devLogger";
import { v4 as uuidv4 } from "uuid";

// create sessionId
const generateSessionId = () => {
  // return Math.random().toString(36).substring(2, 15);
  return uuidv4();
};

// Function to send analytics data when the component mounts
const sendAnalyticsData = async () => {
  const postUrl =
    process.env.NODE_ENV?.toLowerCase().trim() === "production"
      ? `${process.env.REACT_APP_SERVER_PROD_URL}/api/v1/custom-analytics?apiKey=${process.env.REACT_APP_FREE_TRIAL_API_KEY}`
      : `${process.env.REACT_APP_SERVER_DEV_URL}/api/v1/custom-analytics?apiKey=${process.env.REACT_APP_FREE_TRIAL_API_KEY}`;

  // Check if a session ID already exists in sessionStorage
  let sessionId = sessionStorage.getItem("sessionId");

  // If not, generate a new session ID and store it in sessionStorage
  if (!sessionId) {
    sessionId = generateSessionId();
    sessionStorage.setItem("sessionId", sessionId);
  }

  try {
    const response = await fetch(postUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        pageUrl: window.location.pathname,
        sessionId: sessionId,
        // ipAddress: "192.178.1.1", // You can use a library to get the client's IP
      }),
    });

    if (response.ok) {
      devMsgLogger("Analytics data sent successfully.");
    } else {
      devMsgLogger("Failed to send analytics data.");
    }
  } catch (error) {
    devMsgLogger("Error sending analytics data:", error);
  }
};

export const kuvugaVisits = () => {
  sendAnalyticsData();
};

export const kuvugaPageView = () => {
  sendAnalyticsData();
};

export default { kuvugaVisits, kuvugaPageView };
