import React, { useRef } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { useNavigate, useLocation } from "react-router-dom";
import { devMsgLogger, devValueLogger } from "utils/devLogger";

const Content = styled.div`
  ${tw`h-2/3 w-full flex justify-center`}
`;
const Card = styled.div`
  ${tw`h-[70vh] w-full lg:w-1/2`}
`;
const CardText = tw.div`h-5/6 w-full flex flex-col justify-between capitalize`;
const CardTitle = tw.h1`h-2/3 w-full text-4xl font-bold flex justify-center items-center bg-gray-200`;
const CardAction = tw(PrimaryButtonBase)`w-full`;

export default ({ handleRepeat, queryString }) => {
  const navigate = useNavigate();
  const location = useLocation();
  devMsgLogger(location.pathname); // ex: learning
  devMsgLogger(location.search); // ex: ?categories=basics
  const urlSearchValue = location.search.slice(10);
  devValueLogger("search value: ", urlSearchValue);

  const endMsgArr = [
    "Well Done!",
    "Way To Go!",
    "Nice Work!",
    "Outstanding!",
    "Fantastic!",
    "Terrific!"
  ];

  const endMsgRef = useRef(Math.floor(Math.random() * endMsgArr.length));

  return (
    <>
      <Content>
        <Card>
          <CardText>
            <CardTitle>{endMsgArr[endMsgRef.current]}</CardTitle>
            <CardAction onClick={handleRepeat}>REPEAT</CardAction>
            <CardAction
              onClick={() => {
                 navigate(`/lesson-section${queryString}`);
              }}
            >
              Back to Lesson
            </CardAction>
          </CardText>
        </Card>
      </Content>
    </>
  );
};
