import * as React from 'react';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';

export default function Loading({variant, colorValue}) {
  return (
    <Stack sx={{ color: colorValue }} spacing={2} direction="row">
      <CircularProgress color={variant} />
    </Stack>
  );
}