// ExampleAnalyticsComponent.js
import React, { useEffect, useState, Fragment } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingMd } from "components/misc/Layouts";
import Header from "components/headers/secondaryLight.js";
import KuvugaPasscodePage from "../auth/KuvugaPasscodePage";

//
import { kuvugaPageView } from "utils/customAnalyticsUtils";
import tw from "twin.macro";

const TableContainer = tw.div`container mx-auto mb-4 border-2 border-gray-400 rounded-lg`
const Table = tw.table`w-[100%]`
const TableRow = tw.tr`border-2 border-gray-400 mb-4`
const TableData = tw.td`font-medium`
const TableDataTitle = tw.td`font-black w-[35%] pl-2`
const TableDataTitleHead = tw.td`font-black w-[35%] pl-2 bg-gray-400`
const TableDataSession = tw.td`font-medium bg-gray-400`

const TotalsSection = tw.div`container mx-auto mt-8 flex flex-wrap gap-4 mb-6`
const TotalsCard = tw.div`bg-gray-200 p-4 rounded-md shadow-md flex-1`
const TotalsTitle = tw.h2`text-lg font-black mb-2`
const TotalsCount = tw.p`text-2xl`

const CustomAnalyticsPage = () => {
  // custom analytics
  useEffect(() => {
    kuvugaPageView();
  }, []);

  //
  const [analyticsData, setAnalyticsData] = useState([]);

  const fetchUrl =
    process.env.NODE_ENV?.toLowerCase().trim() === "production"
      ? `${process.env.REACT_APP_SERVER_PROD_URL}/api/v1/custom-analytics?apiKey=${process.env.REACT_APP_FREE_TRIAL_API_KEY}`
      : `${process.env.REACT_APP_SERVER_DEV_URL}/api/v1/custom-analytics?apiKey=${process.env.REACT_APP_FREE_TRIAL_API_KEY}`;

  useEffect(() => {
    // Fetch analytics data from the backend API
    const fetchAnalyticsData = async () => {
      try {
        const response = await fetch(fetchUrl);
        if (response.ok) {
          const data = await response.json();
          setAnalyticsData(data);
        } else {
          console.error("Failed to fetch analytics data.");
        }
      } catch (error) {
        console.error("Error fetching analytics data:", error);
      }
    };

    fetchAnalyticsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const totalSessions = analyticsData.length;

  const totalPageViews = analyticsData.reduce((acc, session) => {
    return acc + session.pages.reduce((pageAcc, page) => pageAcc + page.views, 0);
  }, 0);

  const pageStats = analyticsData.flatMap((session) => {
    return session.pages.map((page) => ({
      pageUrl: page.pageUrl,
      views: page.views,
    }));
  });

  return (
    <>
      {!sessionStorage.getItem("sesh-8v2qPzyE") ? (
        <KuvugaPasscodePage />
      ) : (
        <AnimationRevealPage disabled>
          <Header />
          <Container>
            <ContentWithPaddingMd>
              <TotalsSection>
                <TotalsCard>
                  <TotalsTitle>Total Sessions</TotalsTitle>
                  <TotalsCount>{totalSessions}</TotalsCount>
                </TotalsCard>

                <TotalsCard>
                  <TotalsTitle>Total Page Views</TotalsTitle>
                  <TotalsCount>{totalPageViews}</TotalsCount>
                </TotalsCard>

                {pageStats.map((page, index) => (
                  <TotalsCard key={index}>
                    <TotalsTitle>{page.pageUrl}</TotalsTitle>
                    <TotalsCount>{page.views}</TotalsCount>
                  </TotalsCard>
                ))}
              </TotalsSection>

              {/* session tables */}
              {analyticsData.map((session, index) => {
                return (
                  <TableContainer>
                    <Table key={index}>
                      {/* <TableHead> */}
                      <TableRow>
                        <TableDataTitleHead >Session</TableDataTitleHead >
                        <TableDataSession>{session.sessionId}</TableDataSession>
                      </TableRow>
                      {/* </TableHead> */}
                      <tbody>
                        {session.pages.map((page, pageIndex) => {
                          return (
                            <Fragment key={pageIndex}>
                              <TableRow>
                                <TableDataTitle>Page</TableDataTitle>
                                <TableData>{page.pageUrl}</TableData>
                              </TableRow>
                              <TableRow>
                                <TableDataTitle>Views</TableDataTitle>
                                <TableData>{page.views}</TableData>
                              </TableRow>
                              <TableRow>
                                <TableDataTitle>Timestamp{" "}</TableDataTitle>
                                <TableData>
                                  {new Date(page.timestamp).toLocaleString()}
                                </TableData>
                              </TableRow>
                            </Fragment>
                          );
                        })}
                      </tbody>
                    </Table></TableContainer>
                );
              })}
            </ContentWithPaddingMd>
          </Container>
        </AnimationRevealPage>
      )}
    </>
  );
};

export default CustomAnalyticsPage;
