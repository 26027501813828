import React from "react";
import Paper from "@mui/material/Paper";

const KuvugaEmptyBottomNav = ({ children }) => {
  return (
    <Paper
      sx={{
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        display: "flex",
        justifyContent: "center",
        justifyItems: "center",
      }}
      elevation={3}
    >
      {children}
    </Paper>
  );
};

export default KuvugaEmptyBottomNav;
