// import React, { useState } from "react";
import React, { useEffect, useState } from "react";
import tw from "twin.macro";
import Header from "components/headers/secondaryLight.js";
import { PrimaryButton } from "components/misc/Buttons";
import { useNavigate } from "react-router-dom";

import allLanguagesList from "../constants/allLanguagesList.json";
// import KuvugaDropdown from "../components/misc/KuvugaDropdown";

// redux
import { useDispatch } from "react-redux";
import { updateLanguageSetupConfig } from "redux/slices/languageSetupSlice";

// funcs
import { getCookie, setCookie } from "../utils/cookiesHandler";
import generateUniqueIdentifier from "../utils/identifierHandlers";
// import setInStorage from "utils/storageHandler";
import { writeToDB } from "../dexie/dbUtils";

// analytics
import { kuvugaPageView } from "utils/customAnalyticsUtils";
import A2HSComponent from "lib/A2HSComponent";

// components
const Layout = tw.div`bg-gray-100 font-display min-h-screen text-secondary-500 p-8 overflow-hidden`;
const MainContainer = tw.div`w-[80vw] md:w-[70vw] lg:w-[60vw] h-full mx-auto flex flex-col justify-center items-center`;
const AvailableLanguages = tw.div`mt-4 flex flex-col justify-center items-center`;
const Paragraph = tw.p`mt-10 font-black text-center text-2xl md:text-4xl leading-snug max-w-2xl`;
const StayTuned = tw.p`mx-auto text-primary-500 mt-[10rem] font-extrabold text-center text-xl md:text-2xl leading-snug`;
const UnderConstruction = tw.p`mx-auto text-gray-600 mt-2 font-bold text-center text-lg md:text-xl leading-snug`;
const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
const LangButton = tw(
  PrimaryButton
)`w-[80vw] md:w-[70vw] lg:w-[60vw] mb-2 mx-1 capitalize`;

// main comp
export default () => {
  // custom analytics
  useEffect(() => {
    kuvugaPageView();

    if (sessionStorage.getItem("sessionId")) {
      setIsVisible(true);
    }
  }, []);

  // install to home screen// snackbar
  const [isVisible, setIsVisible] = useState(true);
  const handleClose = () => {
    setIsVisible(false);
  };

  // state
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // const [errorState, setErrorState] = useState(false);

  // const langNameArr = [
  //   "english",
  //   // "french",
  //   // "spanish",
  //   // "portuguese",
  //   "kinyarwanda",
  //   // "swahili",
  //   // "linngala",
  //   // "luganda",
  //   // "hausa",
  //   // "rukiga",
  // ];

  const setLearningLanguage = async (selectedLanguage) => {
    // add to redux store
    dispatch(
      updateLanguageSetupConfig({
        knownLanguageState: "english",
        learningLanguageState: `${selectedLanguage.toLowerCase()}`,
      })
    );

    // add to local storage and identifiers
    const setKvgSettings = generateUniqueIdentifier(); // temp
    if (!getCookie("kvglan") || getCookie("kvglan") === "") {
      setCookie("kvglan", `${selectedLanguage}`, 31536000); // temp
    }
    if (!getCookie("kvg_settings") || getCookie("kvg_settings") === "{}") {
      setCookie("kvg_settings", `${setKvgSettings}`, 31536000); // temp
    }

    // write to indexedDB
    writeToDB("userLanSettings", {
      fluent_language: "english",
      learning_language: `${selectedLanguage.toLowerCase()}`,
    });
    writeToDB("userSettings", {
      kvg_setting_one: `${setKvgSettings.kvg_setting_one}`,
      kvg_setting_two: `${setKvgSettings.kvg_setting_two}`,
      pref_theme: "light-mode",
    });

    // go to menu
    navigate("/home-menu");
  };

  return (
    <Layout>
      <Header />
      <MainContainer id="main-containter-id">
        <Paragraph>
          I want to <HighlightedText>Learn</HighlightedText>
        </Paragraph>
        <AvailableLanguages>
          {allLanguagesList?.languages.map((lang, index) => (
            <LangButton key={index} onClick={() => setLearningLanguage(lang)}>
              {lang}
            </LangButton>
          ))}
        </AvailableLanguages>
        <StayTuned>
          Stay Tuned!
        </StayTuned>
        <UnderConstruction>
          Kuvuga is growing! Exciting new developments are on the way. Visit
          often to check out new features and content!
        </UnderConstruction>
      </MainContainer>
      {isVisible && <A2HSComponent handleClose={handleClose} />}
    </Layout>
  );
};
