import React, { useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
// import { useDispatch } from "react-redux";
// import { setLanguageSetupConfig } from "redux/slices/languageSetupSlice";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      textTransform: "capitalize",
    },
  },
};

export default function KuvugaDropdown({
  dropDownStyles,
  placeholderText,
  hasError,
  compArr,
  compHandler,
}) {
  //   const dispatch = useDispatch();

  const [selectedItem, setSelectedItem] = useState("");
  const [hasSelected, setHasSelected] = useState(false);

  const handleChange = (event) => {
    setSelectedItem(event.target.value);
    setHasSelected(true);
    compHandler(event.target.value);

    // add to local storage
    // localStorage.setItem("knownLanguage", `${event.target.value}`);

    // add to redux store
    // dispatch(
    //   setLanguageSetupConfig({
    //     knownLanguageState: event.target.value.toString().toLowerCase(),
    //   })
    // );
  };

  return (
    <div style={dropDownStyles}>
      <FormControl
        size="small"
        sx={{
          mt: 1,
          width: "100vw",
          textTransform: "capitalize",

          "& .MuiInputBase-root": {
            borderColor: `${
              hasError === true && selectedItem === "" ? "red" : "#6415FF"
            }`,
            borderWidth: 1,
            borderStyle: "solid",
            borderRadius: "0.25rem",
            minWidth: "120px",
            justifyContent: "center",
          },
          "& .MuiSelect-select.MuiSelect-select": {
            paddingRight: "0px",
          },
        }}
      >
        <Select
          id={`${selectedItem}-id`}
          displayEmpty
          // multiple
          value={selectedItem}
          onChange={handleChange}
          // input={<OutlinedInput />}
          MenuProps={MenuProps}
          sx={{
            backgroundColor: `${hasSelected === true ? "#6415FF" : null}`,
            color: `${hasSelected === true ? "white" : null}`,
            fontWeight: `${hasSelected === true ? "bold" : "normal"}`,
            "& .MuiSelect-select": {
              border: 1,
              borderColor: `${
                hasError === true && selectedItem === "" ? "red" : "#6415FF"
              }`,
            },
            "& li.Mui-selected": {
              color: "white",
              backgroundColor: "#6415FF",
            },
            "& li.Mui-selected:hover": {
              backgroundColor: "#6415FF",
            },
          }}
        >
          <MenuItem disabled value="">
            <em>{placeholderText}</em>
          </MenuItem>
          {compArr.map((name, index) => (
            <MenuItem
              key={`${name}-idx-${index}`}
              id={`${name}-id`}
              value={name}
            >
              {name}
            </MenuItem>
          ))}
        </Select>
        {hasError === true && selectedItem === "" ? (
          <FormHelperText
            error
            sx={{ textAlign: "center", fontWeight: "bold" }}
          >
            Please Make a Selection
          </FormHelperText>
        ) : null}
      </FormControl>
    </div>
  );
}
