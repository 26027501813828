const playTheWord = (word) => {
  // Check if the browser supports the SpeechSynthesis API
  if ("speechSynthesis" in window) {
    // Create a new SpeechSynthesisUtterance instance
    const speech = new SpeechSynthesisUtterance();

    // Set the text to be spoken
    speech.text = `${word}`;

    // Speak the text
    window.speechSynthesis.speak(speech);
  }
};

// export const getBackendAudio = (audioFile) => {
//   const [audioData, setAudioData] = useState(null);
//   fetch(
//     process.env.NODE_ENV?.toLowerCase().trim() === "production"
//       ? `${process.env.REACT_APP_SERVER_PROD_URL}/api/v1/audio?word=${audioFile}&apiKey=${process.env.REACT_APP_FREE_TRIAL_API_KEY}`
//       : `${process.env.REACT_APP_SERVER_DEV_URL}/api/v1/words?tag=${audioFile}&apiKey=${process.env.REACT_APP_FREE_TRIAL_API_KEY}`,
//     {
//       method: "GET",
//       headers: {
//         "Content-Type": "application/octet-stream",
//         Accept: "applicaton/octet-stream",
//       },
//     }
//   )
//     .then((response) => response.blob())
//     .then((res) => {
//       const audioUrl = new URL.createObjectURL(res);
//         setAudioData(audioUrl);
//     })
//     .catch((e) => {
//       console.log("Fetch error: ", e);
//     });

//   const audio = new Audio(audioFile);
//   audio.play();
// };

export default playTheWord;
