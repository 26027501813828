import React, { useEffect } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingMd } from "components/misc/Layouts";
import EmptyFooter from "components/footers/kuvuga-footers/KuvugaEmptyBottomNav";
import tw from "twin.macro";
import { PrimaryButton } from "components/misc/Buttons";
import { useLocation, useNavigate } from "react-router-dom";

import { kuvugaPageView } from "utils/customAnalyticsUtils";

// components
const Posts = tw.div`mt-4 md:mx-0 lg:-mr-8 flex flex-col justify-center items-center`;
const Paragraph = tw.p`mt-10 font-black text-center text-xl leading-snug max-w-2xl`;
const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
const LangButton = tw(
  PrimaryButton
)`w-[80vw] md:w-[70vw] lg:w-[60vw] mb-2 mx-1 capitalize`;
const ContinueButton = tw(
  PrimaryButton
)`w-[80vw] md:w-[70vw] lg:w-[60vw] my-1 mx-1 capitalize bg-gray-100 text-[#6415FF] border-2 border-[#6415FF]`;

export default () => {
  // custom analytics
  useEffect(() => {
    kuvugaPageView();
  }, []);

  //
  const navigate = useNavigate();
  const location = useLocation();
  console.log("pathname: ", location.pathname);

  const referralSources = [
    "Google",
    "Bing",
    "Word of mouth",
    "App Store",
    "Facebook",
    "Instagram",
    "Tik Tok",
    "X (Twitter)",
    "Direct link or URL entry",
    "Email",
    "Online Ad",
    "Website or Blog",
  ];

  return (
    <AnimationRevealPage disabled>
      <Container>
        <ContentWithPaddingMd>
          <Paragraph>
            Where did you hear about <HighlightedText>Kuvuga</HighlightedText>
          </Paragraph>
          <Posts>
            {referralSources.map((refSrc, index) => (
              <LangButton
                key={index}
                id={`${refSrc}-id`}
                onClick={() => navigate("/language-list")}
              >
                {refSrc}
              </LangButton>
            ))}
          </Posts>
        </ContentWithPaddingMd>
        {/* back to top */}
      </Container>

      <EmptyFooter>
        <ContinueButton
          onClick={() => {
            navigate("/language-list");
          }}
        >
          Continue
        </ContinueButton>
      </EmptyFooter>
    </AnimationRevealPage>
  );
};
