const getElementsByIndices = (indices, largerArray) => {
  const result = [];

  for (const index of indices) {
    if (index >= 0 && index < largerArray.length) {
      result.push(largerArray[index]);
    }
  }

  return result;
}

export default getElementsByIndices;

// Example usage:
//   const largerArray = ['A', 'B', 'C', 'D', 'E'];
//   const indices = [1, 3, 0, 4];

//   const selectedElements = getElementsByIndices(indices, largerArray);
//   console.log(selectedElements); // Output: [ 'B', 'D', 'A', 'E' ]
