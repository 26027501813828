import React, { useEffect, useState } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { ContentWithPaddingMd } from "components/misc/Layouts";
import tw from "twin.macro";
import Header from "components/headers/SecondaryCustom.js";
import { PrimaryButton } from "components/misc/Buttons";
// import { useLocation } from "react-router-dom";

import Fab from "@mui/material/Fab";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import allTopics from "../constants/allTopics.json";
// import KuvugaBottomNav from "components/footers/kuvuga-footers/KuvugaBottomNav";
import KuvugaLessonTable from "components/cards/kuvuga-cards/KuvugaLessonTable";

import { kuvugaPageView } from "utils/customAnalyticsUtils";
import { devValueLogger } from "utils/devLogger";

// components
const LessonsContainer = tw.div`my-2 mx-2 flex flex-col justify-center items-center`;
const ButtonContainer = tw.div`w-[100%] md:w-[60%] flex justify-center mx-auto`;
const ScrollTopButtonContainer = tw.div`flex justify-end sticky`;
const LoadMoreButton = tw(PrimaryButton)`mt-4 mx-2 p-2`;

// main comp
export default () => {
  // custom analytics
  useEffect(() => {
    kuvugaPageView();
  }, []);
  // const location = useLocation();
  // console.log("pathname: ", location.pathname);

  const [visible, setVisible] = useState(10);
  const onLoadMoreClick = () => {
    setVisible((v) => v + 6);
  };

  const onViewAllClick = () => {
    setVisible(50);
  };

  const [LessonsData, setLessonsData] = useState([]);

  useEffect(() => {
    try {
      setLessonsData(allTopics?.topics);
    } catch (error) {
      devValueLogger("Fetch error: ", error);
    }
  }, []);

  return (
    <AnimationRevealPage>
      <Header headerTitle={""} />
      {/* <Container> */}
      <ContentWithPaddingMd>
        <LessonsContainer>
          {allTopics.topics.slice(0, visible).map((topic, index) => (
            <KuvugaLessonTable
              key={index}
              lessonNumber={index + 1}
              lessonName={topic.topicTitle}
              numSections={topic.topicSections}
              isTable={topic.isTable}
            />
          ))}
        </LessonsContainer>
        {visible < LessonsData.length ? (
          <ButtonContainer>
            <LoadMoreButton onClick={onLoadMoreClick}>Load More</LoadMoreButton>
            <LoadMoreButton onClick={onViewAllClick}>View All</LoadMoreButton>
          </ButtonContainer>
        ) : (
          <ScrollTopButtonContainer>
            <Fab
              sx={{
                backgroundColor: "#6415FF",
                color: "#edf2f7",
                ":hover": {
                  bgcolor: "#6415FF",
                  color: "#edf2f7",
                },
              }}
              size="small"
              aria-label="scroll back to top"
              onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
            >
              <KeyboardArrowUpIcon color="#edf2f7" />
            </Fab>
          </ScrollTopButtonContainer>
        )}
      </ContentWithPaddingMd>
      {/* back to top */}
      {/* </Container> */}

      {/* <Footer /> */}
      {/* <KuvugaBottomNav /> */}
    </AnimationRevealPage>
  );
};
