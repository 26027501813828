const groupArrayOfObjects = (arrayToGroup, groupByParam) => {
    // Use reduce to group objects by the 'category' key
    const groupedArray = arrayToGroup.reduce((result, obj) => {
      // Find the existing group based on the 'category' key
      const group = result.find((group) => group[0]?.[groupByParam] === obj[groupByParam]);
  
      if (group) {
        // If group exists, add the object to the existing group
        group.push(obj);
      } else {
        // If no group exists, create a new group with the current object
        result.push([obj]);
      }
  
      return result;
    }, []);
    return groupedArray;
  };

  export default groupArrayOfObjects;
