import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  knownLanguageState: "",
  learningLanguageState: "",
};

const languageSetupConfigSlice = createSlice({
  name: "languageSetupConfig",
  initialState,
  reducers: {
    updateLanguageSetupConfig(state, action) {
      //   state.knownLanguageState = action.payload;
      //   state.learningLanguageState = action.payload;

      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { updateLanguageSetupConfig } = languageSetupConfigSlice.actions;
export default languageSetupConfigSlice.reducer;
