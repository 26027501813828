import React, { useState } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container as ContainerBase } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import illustration from "images/undraw_connected_world_wuay.svg";
import { ReactComponent as LoginIcon } from "feather-icons/dist/icons/log-in.svg";
import { useNavigate } from "react-router-dom"; // redux

// mui
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { devValueLogger } from "utils/devLogger";

// fn comp
const Container = tw(
  ContainerBase
)`min-h-screen bg-primary-900 text-white font-medium flex justify-center -m-8`;
const Content = tw.div`max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`lg:w-1/2 xl:w-5/12 p-6 sm:p-12`;
const MainContent = tw.div`mt-12 flex flex-col items-center`;
const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold`;
const FormContainer = tw.div`w-full flex-1 mt-8`;

const Form = tw.form`mx-auto max-w-xs`;
const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-primary-500 text-gray-100 w-full py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;
const IllustrationContainer = tw.div`sm:rounded-r-lg flex-1 bg-purple-100 text-center hidden lg:flex justify-center`;
const IllustrationImage = styled.div`
  ${(props) => `background-image: url("${props.imageSrc}");`}
  ${tw`m-12 xl:m-16 w-full max-w-sm bg-contain bg-center bg-no-repeat`}
`;

export default ({
  illustrationImageSrc = illustration,
  headingText = "KUVUGA",
  submitButtonText = "Sign In",
  SubmitButtonIcon = LoginIcon,
}) => {
  const navigate = useNavigate();
  const [passcode, setPasscode] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [wrongPasscode, setWrongPasscode] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPasscode, setShowPasscode] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      // if (!passcode || passcode !== process.env.REACT_APP_PASSCODE) {
      //   setWrongPasscode("Wrong Passcode");
      //   setPasscode("");
      //   return;
      // }
      if (passcode === process.env.REACT_APP_PASSCODE) {
        sessionStorage.setItem(
          "sesh-8v2qPzyE",
          'f47b5c86-6703-4e22-bd11-6c2e606bf42e'
        );
        
        navigate("/kuvuga-stats");
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const showPasscodeFn = () => {
    try {
      setShowPasscode(!showPasscode);
    } catch (error) {
      devValueLogger("fetch function error: ", error);
    }
  };

  return (
    <AnimationRevealPage disabled>
      <Container>
        <Content>
          <MainContainer>
            <MainContent>
              <Heading>{headingText}</Heading>
              <FormContainer>
                <Form onSubmit={handleSubmit}>
                  <Input
                    type={showPasscode ? "text" : "password"}
                    placeholder="Passcode"
                    value={passcode}
                    onChange={(e) => {
                      setPasscode(e.target.value);
                    }}
                  />
                  <FormControlLabel
                    sx={{
                      fontWeight: "bold",
                    }}
                    control={
                      <Checkbox
                        sx={{
                          "&.Mui-checked": {
                            color: "#6415FF",
                          },
                        }}
                      />
                    }
                    label="Show Passcode"
                    onClick={showPasscodeFn}
                  />
                  <SubmitButton
                    // type="submit"
                    type="button"
                    onClick={handleSubmit}
                  >
                    <span className="text">
                      {loading ? "Loading..." : submitButtonText}
                    </span>
                  </SubmitButton>
                </Form>
              </FormContainer>
            </MainContent>
          </MainContainer>
          <IllustrationContainer>
            <IllustrationImage imageSrc={illustrationImageSrc} />
          </IllustrationContainer>
        </Content>
      </Container>
    </AnimationRevealPage>
  );
};
