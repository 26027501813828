import React, { useState, useEffect, useCallback, useRef } from "react";
import tw from "twin.macro";
import styled from "styled-components";
// import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
// import { ReactComponent as LocationIcon } from "feather-icons/dist/icons/map-pin.svg";
// import { ReactComponent as TimeIcon } from "feather-icons/dist/icons/clock.svg";
// import Header from "../components/headers/secondaryLight";
// import { Container as ContainerBase } from "components/misc/Layouts.js";
import AnimationRevealPage from "helpers/AnimationRevealPage";
// import words from "../assets/words.json";
import { useNavigate } from "react-router-dom";
import EndPlayingCard from "components/cards/kuvuga-cards/EndPlayingCard";
import { shuffle } from "utils";
import Loading from "components/progress/Loading";
import allTopicTitles from "../../constants/allTopicTitles";
import { Button } from "@mui/material";
import KuvugaBottomNav from "components/footers/kuvuga-footers/KuvugaBottomNav";
import { QUESTIONS_TYPE } from "constants";

import { useSelector } from "react-redux";
import { devMsgLogger } from "utils/devLogger";

const Container = styled.div`
  ${tw`h-[89vh] w-full overflow-hidden flex flex-col justify-between`}
`;
const Content = styled.div`
  ${tw`h-[80vh] w-full flex justify-center`}
`;

const Card = styled.div`
  ${tw`w-full lg:w-1/2`}
`;
const CardItems = tw.div`h-5/6 w-full flex flex-col justify-between capitalize`;
const QuestionTitle = tw.div`h-[30vh] text-4xl mt-1 font-bold flex flex-col justify-center items-center bg-gray-200 capitalize`;
const QuestionDescription = tw.h5`h-1/4 text-base mt-1 font-bold flex justify-center items-center  capitalize`;
const CardQuestionAnswer = tw.h1`h-1/4 w-full text-4xl font-bold flex justify-center items-center bg-gray-100`;
// const CardTranslation = tw.h5`h-1/3 text-2xl mt-1 font-bold flex justify-center items-center bg-gray-100 capitalize`;

// const CardAction = tw(PrimaryButtonBase)`w-full py-1 text-2xl capitalize mt-2`;

// const FooterContainer = tw(
// ContainerBase
// )`w-full lg:w-1/2 mx-auto mb-8 rounded bg-gray-900 text-gray-100`;
const ButtonsContainer = tw.div`mb-8 mt-5 flex justify-around items-center`;
// const DirectionButton = tw(PrimaryButtonBase)``;
const CounterSpan = tw.span`font-bold`;

export default () => {
  const storeSetupValues = useSelector((state) => state?.gameSetupConfig);
  // console.log("from store: ", storeSetupValues);

  const navigate = useNavigate();

  const [wordsData, setWordsData] = useState([]);
  // eslint-disable-next-line
  const [flipped, setFlipped] = useState(false);
  const [index, setIndex] = useState(0);
  const [isCompleted, setIsCompleted] = useState(false);
  const [gameAnswers, setGameAnswers] = useState([]);
  const [correctAnswers, setCorrectAnswers] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState("");
  // const [answerIndex, setAnswerIndex] = useState(null);
  const [randWords, setRandWords] = useState([]);
  const [answered, setAnswered] = useState(false);
  const [categories, setCategories] = useState([]);
  const [isRepeating, setIsRepeating] = useState(false);

  useEffect(() => {
    setCategories(allTopicTitles?.titles);
  }, [categories]);

  const numQuestionsRef = useRef(storeSetupValues.gameNumQuestionsState);
  const setNumQuestions =
    numQuestionsRef.current !== "" ||
    numQuestionsRef.current !== undefined ||
    numQuestionsRef.current !== null
      ? numQuestionsRef.current
      : 5;

  const getCategoryRef = useRef(
    Math.floor(Math.random() * allTopicTitles.titles.length)
  );
  const storeCategoryRef = useRef(storeSetupValues.gameCategoryState);

  const selectedCategory =
    storeCategoryRef.current !== "" ||
    storeCategoryRef.current !== "Any" ||
    storeCategoryRef.current !== undefined ||
    storeCategoryRef.current !== null
      ? storeCategoryRef.current
      : categories[getCategoryRef.current];

  useEffect(() => {
    // fetch data
    // const getCategory = Math.floor(Math.random() * categories.length);
    if (
      getCategoryRef.current !== undefined &&
      getCategoryRef.current !== null &&
      categories[getCategoryRef.current] !== undefined
    ) {
      async function fetchData() {
        try {
          await fetch(
            process.env.NODE_ENV?.toLowerCase().trim() === "production"
              ? `${process.env.REACT_APP_SERVER_PROD_URL}/api/v1/words?tag=${selectedCategory}&apiKey=${process.env.REACT_APP_FREE_TRIAL_API_KEY}`
              : `${process.env.REACT_APP_SERVER_DEV_URL}/api/v1/words?tag=${selectedCategory}&apiKey=${process.env.REACT_APP_FREE_TRIAL_API_KEY}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Accept: "applicaton/json",
              },
            }
          )
            .then((response) => response.json())
            .then((data) => {
              setWordsData(data.words);
            });
        } catch (error) {
          process.env.NODE_ENV?.toLowerCase().trim() === "production"
            ? devMsgLogger("Something went wrong. Please try again!")
            : devMsgLogger("fetch function error: ", error);
        }
      }
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories]); // cannot add categoryIndex because it causes more re-renders

  // array of 4 containing the correct answer
  const generateGameQuestions = useCallback(() => {
    let randomQuestions = [];
    randomQuestions.push(wordsData[index]);
    // console.log("rand array first elt", randomQuestions[0]);
    while (randomQuestions.length < setNumQuestions) {
      let randomQuestionIndex = wordsData.indexOf(
        wordsData[Math.floor(Math.random() * wordsData.length)]
      );
      if (
        !randomQuestions.includes(wordsData[randomQuestionIndex]) &&
        randomQuestionIndex !== wordsData.indexOf(wordsData[index])
      ) {
        if (
          wordsData[randomQuestionIndex].kiny_word_sing !== "" &&
          wordsData[randomQuestionIndex].eng_word_sing !== ""
        ) {
          randomQuestions.push(wordsData[randomQuestionIndex]);
        }
      }
      // }
      setRandWords(shuffle(randomQuestions));
    }
  }, [wordsData, index, setNumQuestions]);

  const generateAnswerArrays = useCallback(
    (arr) => {
      if (arr.length < setNumQuestions) {
        throw new Error("Input array must have at least 10 elements.");
      }

      const result = [];

      for (let i = 0; i < setNumQuestions; i++) {
        const uniqueElements = [];
        let startIndex = i;

        while (uniqueElements.length < 4) {
          const element = arr[startIndex % arr.length];

          if (!uniqueElements.includes(element)) {
            uniqueElements.push(element);
          }

          startIndex++;
        }

        result.push(shuffle(uniqueElements));
      }
      setGameAnswers(result);
      // return result;
    },
    [setNumQuestions]
  );

  useEffect(() => {
    if (!randWords?.length && wordsData?.length && answered === false) {
      generateGameQuestions();
    }
    randWords.length && generateAnswerArrays(randWords);
  }, [
    wordsData,
    index,
    randWords,
    answered,
    generateGameQuestions,
    generateAnswerArrays,
  ]);

  useEffect(() => {
    if (answered === true) {
      // generateGameQuestions();
      setAnswered(false);
    }
  }, [answered, generateGameQuestions]);

  // handlers
  const checkNumber = (number) => {
    if (number > randWords.length - 1) {
      setIsCompleted(true);
    }
    if (number < 0) {
      return 0;
    }
    return number;
  };

  // const handleFlipCard = () => {
  //   flipped ? setFlipped(false) : setFlipped(true);
  // };
  const handleRepeat = () => {
    const gameTypeObj = QUESTIONS_TYPE.find(
      (qstType) => qstType?.title === storeSetupValues.gameTypeState
    );
    setIsRepeating(true);
    setCorrectAnswers(0);
    navigate(`${gameTypeObj?.url}`);
    setIsCompleted(false);
    setIndex(0);
  };

  const handleNextCard = () => {
    if (wordsData?.length) {
      if (
        selectedAnswer === randWords[index]?.kiny_word_sing ||
        selectedAnswer === randWords[index]?.eng_word_sing
      ) {
        setCorrectAnswers(correctAnswers + 1);
      }
    }

    setIndex((index) => {
      let newIndex = index + 1;
      return checkNumber(newIndex);
    });
    setSelectedAnswer("");
    setAnswered(true);
  };

  return (
    <AnimationRevealPage disabled>
      <Container id="main-container-id">
        {/* <Header /> */}
        {isCompleted && (
          <EndPlayingCard
            handleRepeat={handleRepeat}
            score={isRepeating === "true" ? 0 : correctAnswers}
            numQuestions={randWords?.length}
          />
        )}
        {!isCompleted && randWords?.length !== 0 && (
          <>
            <Content id="content-id">
              <Card id="card-id">
                <CardItems id="card-items-id">
                  {randWords?.length === 0 && (
                    <Loading colorValue={"#6415FF"} variant={"inherit"} />
                  )}
                  <QuestionTitle id="question-title-id">
                    <QuestionDescription id="question-description-id">
                      {/* Translate to {flipped ? "english" : "kinyarwanda"} */}
                      Translate
                    </QuestionDescription>
                    {flipped
                      ? randWords[index]?.kiny_word_sing
                      : randWords[index]?.eng_word_sing}
                  </QuestionTitle>
                  {/* <QuestionDescription>translates to:</QuestionDescription> */}
                  <CardQuestionAnswer id="selected-answer-id">
                    <div>{selectedAnswer}</div>
                  </CardQuestionAnswer>
                  {/* <CardTranslation>
                    {flipped
                      ? randWords[index].kiny_word_sing
                      : randWords[index].eng_word_sing}
                  </CardTranslation> */}
                  {/* {randWords.length &&
                    randWords.map((word, idx) => {
                      return (
                        <CardAction onClick={handleSelectedAnswer(idx)}>
                          {flipped ? word[idx].eng_word_sing : word[idx].kiny_word_sing}
                        </CardAction>
                      );
                    })} */}
                  {gameAnswers[index]?.map((word, idx) => {
                    return (
                      <Button
                        variant="contained"
                        size="medium"
                        disableRipple
                        sx={{
                          backgroundColor: "#6415FF",
                          marginBottom: "5px",
                          fontSize: "1rem",
                          fontWeight: "bold",
                          color: "#f3f4f6",
                          ":hover": {
                            bgcolor: "#5011cc",
                            color: "#edf2f7",
                          },
                        }}
                        key={idx}
                        onClick={() => {
                          // setAnswerIndex(idx);
                          setSelectedAnswer(
                            flipped ? word?.eng_word_sing : word?.kiny_word_sing
                          );
                        }}
                      >
                        {flipped ? word?.eng_word_sing : word?.kiny_word_sing}
                      </Button>
                    );
                  })}

                  {/* <CardAction
                    onClick={() => {
                      setAnswerIndex(0);
                      setSelectedAnswer(
                        flipped
                          ? randWords[0]?.eng_word_sing
                          : randWords[0]?.kiny_word_sing
                      );
                    }}
                  >
                    {flipped
                      ? randWords[0]?.eng_word_sing
                      : randWords[0]?.kiny_word_sing}
                  </CardAction>
                  <CardAction
                    onClick={() => {
                      // handleSelectedAnswer(1);
                      setAnswerIndex(1);
                      setSelectedAnswer(
                        flipped
                          ? randWords[1].eng_word_sing
                          : randWords[1].kiny_word_sing
                      );
                    }}
                  >
                    {flipped
                      ? randWords[1]?.eng_word_sing
                      : randWords[1]?.kiny_word_sing}
                  </CardAction> */}
                </CardItems>
              </Card>
            </Content>
            {/* <FooterContainer> */}
            <ButtonsContainer id="buttons-container-id">
              {/* <DirectionButton onClick={handlePreviousCard}>
                  BACK
                </DirectionButton> */}
              <CounterSpan id="card-counter-id">
                Question {index + 1} / {randWords?.length}
              </CounterSpan>
              <Button
                variant="contained"
                size="medium"
                disableRipple
                disableTouchRipple
                disableFocusRipple
                sx={{
                  backgroundColor: "#6415FF",
                  marginBottom: "5px",
                  fontSize: "1rem",
                  fontWeight: "bold",
                  color: "#f3f4f6",
                  ":hover": {
                    bgcolor: "#5011cc",
                    color: "#edf2f7",
                  },
                }}
                onClick={() => {
                  handleNextCard();
                }}
              >
                NEXT
              </Button>
            </ButtonsContainer>
          </>
        )}
        <KuvugaBottomNav />
      </Container>
    </AnimationRevealPage>
  );
};
