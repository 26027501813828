import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Slider from "react-slick";
import tw from "twin.macro";
import styled from "styled-components";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons";
import { ReactComponent as ChevronLeftIcon } from "feather-icons/dist/icons/chevron-left.svg";
import { ReactComponent as ChevronRightIcon } from "feather-icons/dist/icons/chevron-right.svg";
import { ReactComponent as ColumnsIcon } from "feather-icons/dist/icons/columns.svg";
import { ReactComponent as ListIcon } from "feather-icons/dist/icons/list.svg";
import { useLocation, useNavigate } from "react-router-dom";
// import Header from "../components/headers/secondaryLight";
import AnimationRevealPage from "helpers/AnimationRevealPage";
import EndLearningCard from "../components/cards/kuvuga-cards/EndLearningCard";

import Paper from "@mui/material/Paper";
// import Button from "@mui/material/IconButton";
// import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import CircularProgress from "@mui/material/CircularProgress";

// import playTheWord from "utils/audioUtils";
import KuvugaBottomNav from "components/footers/kuvuga-footers/KuvugaBottomNav";
// import { Howl, Howler } from "howler";

import allTopics from "../constants/allTopics.json";
import { getElementsByIndices } from "utils";
import groupArrayOfObjects from "utils/groupArrayOfObjects";

import { kuvugaPageView } from "utils/customAnalyticsUtils";
import { devMsgLogger, devValueLogger } from "utils/devLogger";

const Container = tw.div`relative`;
const Content = tw.div`h-[86vh] lg:h-[92vh] mx-auto flex flex-col justify-around`;

// const HeadingWithControl = tw.div`mt-4 flex flex-col items-center`;
const Controls = tw.div`mb-4 flex justify-center`;
const ControlButton = styled(PrimaryButtonBase)`
  ${tw`my-4 mx-6 rounded-full p-2`}
  svg {
    ${tw`w-6 h-6`}
  }
`;
const PrevButton = tw(ControlButton)``;
const NextButton = tw(ControlButton)``;

const CardSlider = styled(Slider)`
  ${tw`mt-1 drop-shadow-xl`}
  .slick-track {
    ${tw`flex`}
  }
  .slick-slide {
    ${tw`h-full flex justify-center`}
  }
  .button__bar {
    ${tw`align-middle m-auto pt-6 text-center`}
    li {
      ${tw`list-none cursor-pointer inline-block mx-1 p-0`}
    }
    li button {
      ${tw`border-none text-transparent bg-gray-600 block h-[5px] w-[5px] rounded-full`}
    }
    li.slick-active button {
      ${tw`bg-primary-500`}
    }
  }
`;
const Card = tw.div`h-full flex! flex-col items-center justify-center rounded focus:outline-none bg-gray-100`;

const TextInfo = tw.div`h-[50vh] w-[80vw] lg:w-[60vw] flex flex-col items-center justify-start`;
const Title = tw.div`w-full text-lg font-bold flex flex-col justify-center items-center capitalize drop-shadow-md rounded`;
const Text = tw.div`italic text-base font-bold text-gray-600 capitalize`;
const Comment = tw.p`text-center text-xs text-secondary-100 font-semibold my-auto`;

const PrimaryButton = tw(PrimaryButtonBase)`mt-auto sm:text-lg w-full rounded`;
const CounterSpan = tw.span`font-bold my-auto mx-4`;

const Table = tw.table`table-auto w-[90%] h-[100%]`;
const TableBody = tw.tbody``;
const TableRow = tw.tr`m-2`;
const TableData = tw.td``;
const ChangeView = tw.div`w-full flex justify-end`;
const ViewButton = tw(ControlButton)`m-1`;

export default () => {
  // custom analytics
  useEffect(() => {
    kuvugaPageView();
  }, []);

  //
  const navigate = useNavigate();
  const location = useLocation();
  const urlSearchValue = location.search.slice(10);
  if (process.env.NODE_ENV?.toLowerCase().trim() === "development") {
    devMsgLogger(location.pathname); // ex: learning
    devMsgLogger(location.search); // ex: ?categories=basics
    devValueLogger("search value: ", urlSearchValue);
  }

  const [sectionInfo, setSectionInfo] = useState({});
  // const [loading, setLoading] = useState(false);
  const [wordsData, setWordsData] = useState([]);
  const [flipped, setFlipped] = useState(false);
  // const [cardIndex, setCardIndex] = useState(0);
  const [isCompleted, setIsCompleted] = useState(false);
  // const [rotated, setRotated] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  // const [audioData, setAudioData] = useState(null);
  const [tableView, setTableView] = useState(false);

  // useState is used instead of useRef below because we want to re-render when sliderRef becomes available (not null)
  const [sliderRef, setSliderRef] = useState(null);
  const sliderSettings = {
    arrows: false,
    dots: true,
    dotsClass: "button__bar",
    slidesToShow: 1,
    slidesToScroll: 1,
    // infinite: false,
    // responsive: [
    //   {
    //     breakpoint: 1280,
    //     settings: {
    //       slidesToShow: 1,
    //     },
    //   },

    //   {
    //     breakpoint: 900,
    //     settings: {
    //       slidesToShow: 1,
    //     },
    //   },
    // ],
  };

  useEffect(() => {
    const query = location.search;
    const queryObject = {};

    const searchParams = new URLSearchParams(query);
    for (const [key, value] of searchParams.entries()) {
      queryObject[`${key}`] = `${value}`;
    }
    setSectionInfo(queryObject);
  }, [location.search]);

  devValueLogger("query params: ", sectionInfo);

  // get section object; filter will return an array with on object
  let sectionIndicesRef = useRef({});
  useEffect(() => {
    // let sectionObj = [];
    if (
      sectionInfo.section &&
      sectionInfo.section !== "" &&
      Object.keys(sectionIndicesRef.current).length === 0
    ) {
      const sectionObj = allTopics.topics.filter((topic) => {
        return topic.topicTitle === sectionInfo.category;
      });
      sectionIndicesRef.current = sectionObj[0].indices;
      // console.log("Indices: ", sectionIndicesRef.current);
    }
  }, [sectionInfo.category, sectionInfo.section]);

  // PROD: fetch data
  useEffect(() => {
    async function fetchData() {
      axios
        .get(
          process.env.NODE_ENV?.toLowerCase().trim() === "production"
            ? `${process.env.REACT_APP_SERVER_PROD_URL}/api/v1/conversations?convo_num=${sectionInfo.category}&apiKey=${process.env.REACT_APP_FREE_TRIAL_API_KEY}`
            : `${process.env.REACT_APP_SERVER_DEV_URL}/api/v1/conversations?category=${sectionInfo.category}&apiKey=${process.env.REACT_APP_FREE_TRIAL_API_KEY}`,
          // `${process.env.REACT_APP_SERVER_DEV_URL}/api/v1/conversations?cnum=${sectionInfo.cnum}&apiKey=${process.env.REACT_APP_FREE_TRIAL_API_KEY}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Accept: "applicaton/json",
            },
          }
        )
        // .then((response) => response.json())
        .then((res) => {
          // if (process.env.NODE_ENV?.toLowerCase().trim() === "development") {
          //   console.log("res: ", res?.conversations);
          // }

          // if (sectionInfo.section && sectionInfo.section !== "") {
          //   const getIndices = (idxNum) => {
          //     allTopics.topics.filter((topic) => topic.topicTitle === idxNum);
          //   };
          //   const idxFound = getIndices(sectionInfo.category);
          // }
          // console.log('idx array: ', sectionIndicesRef.current[`section ${sectionInfo.section}`])

          const convos = groupArrayOfObjects(
            res?.data?.conversations,
            "convo_num"
          );
          if (sectionInfo.section && sectionInfo.section !== "") {
            setWordsData(
              getElementsByIndices(
                sectionIndicesRef.current[`section ${sectionInfo.section}`],
                groupArrayOfObjects(res?.data?.conversations, "convo_num")
              )
            );
          } else {
            setWordsData(convos);
          }
        })
        .catch((error) => {
          process.env.NODE_ENV?.toLowerCase().trim() === "production"
            ? devMsgLogger("Something went wrong. Try reloading!")
            : devMsgLogger("fetch function error: ", error);
        });
    }

    //
    fetchData();
  }, [sectionInfo.category, sectionInfo.section, sectionInfo.cnum]);

  const handleFlipCard = () => {
    // flipped ? setFlipped(false) : setFlipped(true);
    setFlipped(!flipped);
    // setRotated(!rotated);
  };

  const handleRepeat = () => {
    navigate(0);
  };

  return (
    <AnimationRevealPage disabled>
      <Container>
        {/* <Header /> */}
        {wordsData.length === 0 && (
          <Paper
            elevation={3}
            sx={{
              height: "60vh",
              width: "80vw",
              margin: "5vh auto",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#edf2f7",
            }}
          >
            <CircularProgress
              sx={{
                color: "#6415FF",
              }}
            />
          </Paper>
        )}
        {isCompleted && <EndLearningCard handleRepeat={handleRepeat} />}
        {!isCompleted && wordsData.length !== 0 && (
          <Content>
            <CardSlider
              beforeChange={(currentSlide, nextSlide) => {
                setCurrentIndex(nextSlide);
              }}
              ref={setSliderRef}
              {...sliderSettings}
            >
              {wordsData.map((cardArray, index) => (
                <Card key={`card-${index}`}>
                  <TextInfo key={`text-info-${index}`}>
                    {tableView && (
                      <Table id={`table-${index}`}>
                        <TableBody id={`table-body-${index}`}>
                          {cardArray.map((card, idx) => {
                            return (
                              <>
                                <TableRow
                                  id={`table-row-${idx}`}
                                  key={`table-row-${idx}`}
                                >
                                  <TableData id={`table-data-${idx}`}>
                                    <Title id={`title-${idx}`}>
                                      {flipped
                                        ? card.kiny_sent_sing
                                        : card.eng_sent_sing}
                                    </Title>
                                  </TableData>
                                  <TableData>
                                    <Text>
                                      {flipped
                                        ? card.eng_sent_sing
                                        : card.kiny_sent_sing}
                                    </Text>
                                  </TableData>
                                </TableRow>
                              </>
                            );
                          })}
                        </TableBody>
                      </Table>
                    )}

                    {!tableView &&
                      cardArray.map((card, idx) => {
                        return (
                          <>
                            <Title
                              key={idx}
                              style={
                                location.pathname === "/reviewing"
                                  ? { height: "100%" }
                                  : { height: "70%" }
                              }
                            >
                              {flipped
                                ? card.kiny_sent_sing
                                : card.eng_sent_sing}

                              <Text>
                                {flipped
                                  ? card.eng_sent_sing
                                  : card.kiny_sent_sing}
                              </Text>

                              {/* <Button
                        variant="contained"
                        sx={{ color: "#6415FF" }}
                        onClick={
                          flipped
                            ? () => playTheWord(card.eng_sent_sing)
                            : () => handleAudioPlay(card.kiny_sent_sing)
                        }
                      >
                        <VolumeUpIcon />
                      </Button> */}
                            </Title>
                            <Comment>
                              {flipped ? card.eng_comments : card.kiny_comments}
                            </Comment>
                          </>
                        );
                      })}
                  </TextInfo>
                  <ChangeView>
                    <ViewButton
                      onClick={() => {
                        setTableView(!tableView);
                      }}
                    >
                      <ColumnsIcon />
                    </ViewButton>
                    <ViewButton
                      onClick={() => {
                        setTableView(!tableView);
                      }}
                    >
                      <ListIcon />
                    </ViewButton>
                  </ChangeView>
                  <PrimaryButton onClick={handleFlipCard}>FLIP</PrimaryButton>
                </Card>
              ))}
            </CardSlider>
            {/* <HeadingWithControl> */}
            <Controls>
              <PrevButton
                onClick={() => {
                  sliderRef?.slickPrev();
                }}
              >
                <ChevronLeftIcon />
              </PrevButton>
              <CounterSpan>
                {currentIndex + 1} / {wordsData.length}
              </CounterSpan>
              <NextButton
                onClick={() => {
                  if (currentIndex === wordsData.length - 1) {
                    // return 0;
                    setIsCompleted(true);
                    return;
                  }
                  sliderRef?.slickNext();
                }}
              >
                <ChevronRightIcon />
              </NextButton>
            </Controls>
          </Content>
        )}
        <KuvugaBottomNav />
      </Container>
    </AnimationRevealPage>
  );
};
