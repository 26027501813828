import { devMsgLogger, devValueLogger } from "./devLogger";

const pingServerRender = () => {
  fetch(
    process.env.NODE_ENV?.toLowerCase().trim() === "production"
      ? `${process.env.REACT_APP_SERVER_PROD_URL}/api/v1/wake-up`
      : `${process.env.REACT_APP_SERVER_DEV_URL}/api/v1/wake-up`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "applicaton/json",
      },
    }
  )
    .then(() => {
      devMsgLogger("pinging...");
    })
    .catch((error) => {
      devValueLogger("fetch function error", error);
    });
};

export default pingServerRender;
