import { configureStore } from "@reduxjs/toolkit";
import questionsBucketReducer from "./slices/questionsBucketSlice";
import gameSetupConfigReducer from "./slices/gameSetupSlice";
import gameProfileConfigReducer from "./slices/gameSetupSlice";
import languageSetupConfigReducer from "./slices/languageSetupSlice";

export const store = configureStore({
  reducer: {
    questionsBucket: questionsBucketReducer,
    gameSetupConfig: gameSetupConfigReducer,
    gameProfileConfig: gameProfileConfigReducer,
    languageSetupConfig: languageSetupConfigReducer,
  },
});
